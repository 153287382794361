.container {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  border-right: 1px solid rgb(236, 236, 236);
  background-color: var(--background-color);
}
.container::-webkit-scrollbar {
  width: 0px;
}

.container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  width: 0px;
}

.container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  width: 0px;
}

.header {
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: center;
}
.header h1 {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  flex: 1;
  margin-left: 10px;
}
.header img {
  width: 40px;
  margin-left: 20px;
}
.navItems {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}
.navItem,
.activeNavItem {
  display: flex;
  width: calc(90% - 50px);
  padding: 12px 25px;
  align-items: center;
  color: #333;
  margin: 6px 0px;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.5s;
}
.navItem p,
.activeNavItem p {
  margin: 0px;
  margin-left: 15px;
  font-size: 14px;
}
.activeNavItem p {
  color: white;
}
.navItem:hover,
.navItem:hover p {
  cursor: pointer;
  color: var(--primary-color);
  text-decoration: none;
}
.activeNavItem:hover {
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.activeNavItem:hover p {
  color: white;
}
.activeNavItem {
  color: white;
  background-color: var(--primary-color);
}
.menuButton {
  display: none !important;
}
@media only screen and (max-width: 1450px) {
  .navItem,
  .activeNavItem {
    padding: 14px 18px;
    width: calc(90% - 30px);
  }
}
@media only screen and (max-width: 900px) {
  .mobileNav {
    width: 300px;
  }
  .navItem,
  .activeNavItem {
    margin: 6px auto;
  }
  .menuButton {
    display: flex !important;
    position: absolute !important;
    z-index: 1001 !important;
    height: var(--top-bar-height) !important;
    margin-left: 10px !important;
  }
}
