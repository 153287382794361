.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.flex {
    display: flex;
    margin: 15px auto;
    align-items: center;
    /* justify-content: space-between; */
    border-radius: 4px;
    gap: 1rem;
}
/* .justify{
    justify-content: flex-start !important;
} */

.justifyCenter {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}

.textFieldContainer {
    display: flex;
    justify-content: space-between; /* Add margin between the TextFields */
}

.container {
    max-width: 48svw;
    min-width: 48svw;
    border-radius: 20px;
    max-height: 80vh;
    min-height: 40vh;
    padding: 7px;
    background-color: var(--primary-bg-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
}

.container p {
    color: var(--primary-color);
    font-size: 14px;
    margin: 15px 0px;
    text-align: left;
    width: 100%;
}
.select {
    width: 270px;
    border-radius: 10px !important;
    margin-bottom: 95px;
    margin-top: 15px;
}
.subscription {
    text-align: center;
}
.subscription h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    color: var(--primary-color);
}
.subscription p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: var(--primary-color);
    font-size: 18px;
    line-height: 25px;
}
.closeButton {
    text-align: right;
    max-height: 30px;
    color: var(--primary-color);
    width: 100%;
    cursor: pointer;
}

.container .image {
    width: 100%;
    margin: 10px 0;
    text-align: center;
}
.halfField {
    /* width: 30rem; */
}

.Button {
    width: 100%;
    text-align: center;
    margin: 15px auto;
}
.Button button {
    width: 150px;
    cursor: pointer;
    height: 40px;
    color: white;
    background: #2d4141;
    border-radius: 20px;
}
.Button span {
    cursor: pointer;
}
.container .content {
    padding: 0 12px 12px 12px;
    width: 90%;
    margin: 0 auto;
}

.container hr {
    width: 100%;
    background-color: var(--primary-color);
    height: 1px;
    opacity: 0.5;
}

.container .goal {
    width: 100%;
    text-align: center;
}

.content p {
    font-size: 14px;
    line-height: 24px;
}

.title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: var(--primary-color);
    margin: 15px 0;
}

.infoHeader {
    width: 100%;
    margin-bottom: 15px;
    text-align: left;
}

.infoHeader h5 {
    text-transform: uppercase;
    color: var(--primary-color);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
}

.progressContent {
    min-width: 300px;
    background-color: rgba(45, 65, 65, 0.3);
    height: 5px;
    border-radius: 4px;
    display: inline-block;
}
.progressBar {
    height: 100%;
    border-radius: 4px;
    background-color: var(--primary-color);
}
.additional {
    width: 60%;
    gap: 15px;
    display: flex;
    flex-direction: column;
}
.additional h4 {
    margin-top: 40px;
}

.subscription {
    width: 100%;
    margin-top: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.subscription h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    color: var(--primary-color);
}
.subscription p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: var(--primary-color);
    font-size: 18px;
    line-height: 25px;
}

.contactSection {
    width: 100%;
    gap: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactHeader {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
}

.contactHeader h5 {
    text-transform: uppercase;
    color: var(--primary-color);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
}

@media only screen and (max-width: 450px) {
    .container {
        max-width: 80vw;
    }
}
