.modal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.paper {
    width: 700px;
    padding: 40px;
    margin: 0 auto;
    padding: 0px 30px;
    position: relative;
}
.close {
    position: absolute !important;
    top: 15px !important;
    right: 15px !important;
}
.paper h1 {
    font-size: 20px;
    padding-top: 20px;
    margin: 10px 0px;
    font-weight: 500;
}
.row {
    display: flex;
    align-items: center;
    margin: 15px 0px;
}
.halfWidth {
    width: 48%;
    margin: 20px !important;
    margin-left: 10px !important;
}
.rowWarehouse {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.selectBlock {
    width: 30%;
    margin: 15px 10px;
    display: flex;
    align-items: center;
}
.select {
    width: 100% !important;
}
.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
}
.info p {
    font-size: 16px;
}

@media only screen and (max-width: 700px) {
    .paper {
        padding: 0px 15px;
    }
    .row {
        flex-direction: column;
        align-items: flex-start;
    }
    .halfWidth {
        width: 96%;
    }
}