.container {
    width: 100%;
    margin: 10px 0px;
}
.content {
    width: 100%;
}
.contentAlign {
    display: flex;
    align-items: center;
}
.rowContent {
    height: 65px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.dayContainer {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.textField {
    margin-right: 10px !important;
    position: relative;
}
.textField label {
    font-size: 13px !important;
    color: var(--primary-color) !important;
    margin-bottom: 5px !important;
}
.dayContainer p {
    margin-top: 0px;
    color: var(--primary-color);
}
.timeLine {
    flex: 1;
    height: 100%;
    position: relative;
    margin: 0px 20px;
    display: flex;
    align-items: center;
} 
.timeSlotComp {
    width: 100%;
    flex: 1; 
    padding: 0px 15px;
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 10px;
}
.timeSlotCompDiv  {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.timeSlotInfo {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
} 
.disbaled {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
}
.header {
    height: 80px;
    width: 100%;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.columnContent {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.columnContent h1 {
    color: var(--primary-color);
    font-size: 14px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}