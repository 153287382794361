.container {
    width: 100%;
    margin-bottom: 30px;
}
.loader {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content {
    width: 96%;
    margin: 0 auto;
    margin-top: 50px;
}
.content h1 {
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600;
    margin-top: 20px;
    border-bottom: 1px solid rgb(0, 0, 0,0.1);
    padding-bottom: 10px;
}
.content hr {
    margin: 25px 0px;
}
.row {
    display: flex;
    align-items: flex-start;
    margin: 20px 0px;
    font-size: 14px !important;
    justify-content: space-between;
}
.textField {
    width: 48%;
    position: relative; 
}
.textField label,
.textField p {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.checkText {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 5px 0px;
    color: var(--primary-color);
}
.imgContainer,
.imgContainerError {
    width: 22%;
    min-height: 220px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.1);
    border-radius: 4px;
}
.imgContainerError {
    border: 1px solid red;
}
.imgContainer img,
.imgContainerError img {
    max-width: 90% !important;
    max-height: 90% !important;
    position: absolute !important;
}
.iconButton {
    background-color: red !important; 
    position: absolute !important;
    top: 0px !important;
    right: 10px !important;
    z-index: 10 !important;
    color: white !important;
}
.iconButton:hover {
    background-color: rgba(255, 0, 0, 0.89);
    cursor: pointer;
} 
.imgLogoContainer,
.imgLogoContainerError {
    width: 22%;
    min-height: 220px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.1);
    border-radius: 4px;
}
.imgLogoContainerError {
    border: 1px solid red;
}
.imgLogoContainer img,
.imgLogoContainerError img {
    max-width: 90% !important;
    max-height: 90% !important;
    position: absolute !important;
} 
.imgLogoContainer:hover {
    cursor: pointer;
} 
.imgLogoContainer p,
.imgLogoContainerError p,
.imgContainer p,
.imgContainerError p {
    position: absolute;
    bottom: -25px;
    color: var(--primary-color);
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.btnContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.row1 {
    width: 48%;
}

.fileUploader {
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fileInfo {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border:  1px solid rgb(0, 0, 0,0.2);
    padding: 0px 10px;
    border-radius: 4px;
    margin: 0px 20px;
    height: 40px;
}
.fileInfo span {
    font-size: 14px;
}
.fileUploader {
    position: relative;
}
.fileUploader svg {
    margin: 0px 10px;
}
.fileUploader svg:hover {
    cursor: pointer;
}
.fileUploader button {
    width: 140px;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: var(--primary-bg-color);
    font-size: 15px;
    background-color: var(--primary-color);
}
.labelDoc {
    display: flex;
    align-items: center;
}
.flexAlignRow {
    display: flex;
    align-items: center;
}
.error {
    color: red;
    font-weight: 400;
    font-size: 13px;
}
.mapHere {
    width: 100%;
    position: absolute;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 700px) {
    .row {
        flex-direction: column;
    }
    .textField {
        width: 100%;
        margin: 6px 0px !important;
    }
    .imgLogoContainer,
    .imgLogoContainerError,
    .imgContainer,
    .imgContainerError {
        width: 100%;
        margin: 20px 0px;
    }
}
