@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;1,200&display=swap");

* {
    outline: none !important;
    margin: 0;
    padding: 0px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

:root {
    --primary-color:#2D4141;
    --secondary-color: #FFD719;
    --primary-color-light: rgba(39, 67, 253, 0.75);
    
    --primary-bg-color: white;
    --background-color: white;
    --bg-green: rgba(51, 62, 50, 0.4);
    --text-color: #333;
    
    --heading: 18px;
    --sub-heading: 16px;
    --content: 14px;

    --nav-width: 300px;
    --main-navbar-height: 70px;
    --breadcrump-height: 45px;

    --background-color: white;
    --text-color: #333;
    
    --heading: 18px;
    --sub-heading: 16px;
    --content: 14px;

    --nav-width: 300px;
    --breadcrump-height: 45px;
    --top-bar-height: 74px;
}

body::-webkit-scrollbar {
    width: 2px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    width: 4px;
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    width: 4px;
} 
p {
    font-size: 14px;
    color: #333;
}
pre {
  white-space: pre-wrap;
}

.actions {
    display: flex;
    align-items: center;
}
.sc-fodVxV .gbjtWJ .rdt_TableHeader  {
    display: none !important;
}
.MuiMenuItem-root .MuiMenuItem-gutters {
    font-size: 13px !important;
}
.text-orange {
    color: orange;
}
.text-green {
    color: green;
}
.text-red {
    color: red;
}
.input-range__track--active,
.input-range__slider {
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}
.btnGreen {
    padding: 12px 14px;
    font-size: 14px;
    border-radius: 2px;
    background-color: var(--primary-color);
    color: var(--primary-bg-color);
    border: none;
    text-transform: uppercase;
}
.btnRed {
    padding: 12px 14px;
    font-size: 14px;
    background-color: red;
    color: var(--primary-bg-color);
    border: none;
    text-transform: uppercase;
}
.btnLight {
    padding: 12px 14px;
    font-size: 14px;
    background-color: var(--primary-bg-color);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    text-transform: uppercase;
}
.btnYellow {
    padding: 12px 14px;
    font-size: 14px;
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    text-transform: uppercase;
}
.btnBorderGreen {
    padding: 12px 14px;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    text-transform: uppercase;
}
.btnBorderDisabled {
    padding: 12px 14px;
    font-size: 14px;
    background-color: #ccc;
    border: 1px solid var(#ccc);
    text-transform: uppercase;
    opacity: 0.9;
    color: white;
    pointer-events: none;
}

.btnBorderGreen:hover {
    background-color: var(--primary-color);
    color: var(--primary-bg-color);
}
.btnGreen:hover,
.btnLight:hover,
.btnYellow:hover,
.btnBorderGreen:hover,
.btnRed:hover {
    cursor: pointer;
}
.btnCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.greenColor {
    color: green;
    text-align: center; 
}
.redColor {
    color: red;
    text-align: center; 
}
.hover:hover {
    cursor: pointer;
}

.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
    z-index: 0 !important;
    position: relative !important;
}

@media only screen and (max-width: 1450px) {
    :root {
        --nav-width: 250px;
    }
}

@media only screen and (max-width: 900px) {
    :root {
        --nav-width: 0px;
        --top-bar-height: 64px;
    }
}
