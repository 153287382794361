.container {
    width: 100%;
}
.content {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}
.navItem {
    border: 1px solid var(--primary-color);
    padding: 20px;
    /* width: 450px; */
    width: 40%;
    margin: 20px;
    border-radius: 4px;
    display: flex;
    height: 45px;
    opacity: 0.8;
    align-items: center;
}
.infoContent {
    margin-left: 20px;
}
.infoContent h1 {
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 500;
}
.infoContent p {
    color: var(--primary-color);
    font-size: 14px;
}
.navItem:hover {
    cursor: pointer;
}