.container {
    width: 100%;
}
.content {
    width: 96%;
    margin: 0 auto;
    padding: 15px 0px;
}
.content h1 {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin: 5px 0px;
}
.content p {
    font-size: 14px;
    color: #333;
    margin: 5px 0px;
}
.header {
    display: flex;
    margin: 10px 0px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.tabs button {
    text-transform: capitalize !important;
    font-weight: 600;
}

.tableHead {
    display: flex; 
    padding: 25px 15px;
    align-items: center;
    justify-content: space-between;
}
.tableHead h2 {
    font-size: 14px;
    color: #333;
    font-weight: 600;
    width: 14.2%;
}
.bookingContent {
    background-color: rgb(45, 65, 65,0.05);
}
.alignCenter {
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}