.container {
    width: 100%;
    margin: 10px 0px;
}
.content {
    width: 90%;
    margin: 0 auto;
}
.content h1 {
    font-size: 18px;
    font-weight: 400;
    margin: 15px 0px;
    color: var(--primary-color);
}
.rowContent {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px 0px; 
    padding: 10px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.rowContent h4 {
    font-size: 16px; 
    margin: 5px 0px;
    color: var(--primary-color);
    font-weight: 400;
}
.rowContent p {
    opacity: 0.8;
    color: var(--primary-color);
}
.content button {
    margin-left: 20px;
    padding: 8px 14px;
    width: 160px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-bg-color);
    color: var(--primary-color);
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 2px;
    transition: all .4s;
}
.content button:hover {
    cursor: pointer;
    color: var(--primary-bg-color);
    background-color: var(--primary-color);
}
.btnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnContainer button {
    margin: 10px;
}
.alignCenter {
    display: flex;
    align-items: center;
}
.spanText {
    font-family: 'Montserrat',sans-serif !important;
    font-size: 13px;
    color: var(--primary-color);
    margin: 15px 5px;
}