.container {
    width: 100%;  
    padding-bottom: 50px;
    display: flex;
}
.head {
    margin: 50px 0px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}
.content {
    width: 90%; 
    display: flex;
    margin: 25px auto;
}
.leftContent {
    width: calc(60% - 50px);
    margin-right: 2%;
    padding: 15px 25px;
    border-radius: 8px;
    background-color: var(--primary-bg-color);
}
.rightContent {
    width: 38%; 
    border-radius: 8px; 
}