.container {
    width: 100%;
    margin: 10px 0px;
}
.content {
    width: 90%;
    margin: 0 auto;
}
.content h1 {
    font-size: 18px;
    font-weight: 400;
    margin: 15px 0px;
    color: var(--primary-color);
}
.content p {
    font-size: 13px;
    opacity: 0.8;
    color: var(--primary-color);
}
.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 25px 0px;
}
.textField ,
.searchField {
    width: 48% !important;
    position: relative !important; 
    color: var(--primary-color) !important;
}
.textField div{
    background-color: var(--primary-bg-color) !important;
    color: var(--primary-color) !important;
}
.radioButtons {
    display: flex;
    align-items: center;
    width: 48%;
}
.imgContainer {
    margin: 25px 0px;
    width: 120px;
    height: 120px;
    border-radius: 100%;
}
.imgContainer img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 100%;
}
.imgContainer:hover {
    cursor: pointer;
}
.rowButton {
    display: flex;
    width: 48%;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
}
.rowButton .textField {
    width: 100% !important;
}
.rowButton button {
    margin-top: 20px !important;
}
.profileContainer {
    display: flex;
    align-items: center;
}
.choose {
    margin-left: 20px;
    padding: 8px 14px;
    width: 120px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-bg-color);
    color: var(--primary-color);
    text-transform: uppercase;
    font-weight: 500;
}
.choose:hover {
    cursor: pointer;
}
.label {
    color: var(--primary-color) !important;
    font-size: 14px !important;
    margin-bottom: 5px !important;
}
.rowContent {
    flex: 1;
    width: 52%;
    display: flex;
    align-items: center;
} 