.container {
    width: 100%;
}
.loader {
    width: 100%; 
    padding: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contentCenter {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contentCenter svg {
    color: rgb(114, 114, 114);
    font-size: 60px;
    margin: 10px 0px;
}
.listItems {
    display: flex;
    flex-direction: column;
}
.listItem,
.activeListItem {
    padding: 6px 12px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    font-weight: 400;
}
.activeListItem p,
.listItem p {
    font-size: 15px;
    color: rgb(88, 88, 88);
}
.listItem:hover{
    cursor: pointer;
    background-color: rgba(204, 204, 204, 0.19);
}
.listItem:hover p,
.activeListItem p  {
    color: #000;
} 
.activeListItem {
    background-color: rgba(204, 204, 204, 0.49);
}
.hoverItems {
    display: flex;
}