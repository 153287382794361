.contentContainer { 
    width: 100%;
}
.contentContainer h2{ 
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(72, 101, 101,0.2);
    color: var(--primary-color);
    font-weight: 700;
    font-size: 16px;
    margin: 10px 0px;
}
.contentContainer h3 {
    font-size: 15px;
    font-weight: 700;
    color: var(--primary-color);
    margin: 10px 0px;
    margin-top: 25px;
}
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.halfField {
    width: 48% !important;
    position: relative;
}
.fullField {
    width: 100% !important;
    position: relative;
}
.halfField label {
    font-size: 13px !important;
    margin-bottom: 5px !important;
    color: var(--primary-color) !important;
}
.rowFlexStart {
    display: flex;
    align-items: center;
    width: 100%;
}
.item {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.formGroup {
    width: 150px;
    margin-right: 15px;
}
.formGroup label {
    font-size: 13px;
    color: var(--primary-color);
}
.formGroup input {
    width: 100%;
    border-radius: 4px;
    height: 54px;
    font-size: 14px;
    margin-top: 6px;
    text-indent: 10px;
    border: 1px solid rgba(51, 51, 51, 0.3);
}
.greyBorder {
    border:2px solid #E1E1E1 !important;
}
.greenBorder {
    border:2px solid #D6E4C4 !important; 
}
.blueBorder {
    border:2px solid #C2E0F5 !important;
}
.error {
    color: red;
    font-size: 11px;
}
.spacer {
    width: 100px;
}
.menuItems div:nth-child(3){
    background-color: #F3F4F4 !important;
    color: var(--primary-color) !important;
    font-size: 14px !important;
}
.item span {
    padding: 3px;
}