.container {
    padding: 20px 0px;
    margin: 20px 0px;
}
.content {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.leftSide {
    width: 50%;
}
.rightSide {
    width: 47%;
    margin-left: 1%;
    display: flex;
}
.list {
    padding: 22px 0px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(230, 225, 210, 0.1);
}
.leftSide h1 {
    font-size: 18px;
    font-family: "Lora", sans-serif !important;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: var(--primary-bg-color);
}
.leftSide h4 {
    font-size: 16px;
    font-family: "Lora", sans-serif !important;
    font-weight: 400;
    letter-spacing: 0.6px;
    font-style: italic;
    color: var(--primary-bg-color);
}
.tag {
    padding: 6px;
    border: 1px solid var(--color-1);
    color: var(--primary-bg-color);
    font-size: 12.5px;
    opacity: 0.8;
    border-radius: 3px;
    letter-spacing: 0.4px;
}
.leftSide pre {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: var(--primary-bg-color);
    font-family: "Lora", sans-serif !important;
    font-style: italic;
    font-weight: 400;
    opacity: 0.8;
}
.mainImg {
    width: 100%;
    max-height: 300px;
    position: relative;
}
.mainImg video,
.mainImg img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 2px;
}
.sideImg {
    height: 200px;
    width: 200px;
    position: relative;
    margin-left: 10px;
}
.sideImg img {
    width: inherit;
    height: inherit;
    border-radius: 2px;
    object-fit: contain;
}

@media only screen and (max-width: 600px) {
    .content {
        width: 90%;
        flex-direction: column;
    }
    .leftSide {
        width: 100%;
        margin-bottom: 10px;
    }
    .rightSide {
        width: 100%;
    }
}
