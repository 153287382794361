.container {
    width: 100%;
    margin: 10px 0px;
}
.container h3 {
    padding-left: 28px;
    padding-top: 30px;
    font-family: "Montserrat", sans-serif;
    color: var(--primary-color);
    font-weight: 600;
    font-size: 18px;
}
.content {
    width: 100%;
    padding-top: 40px;

    display: flex;
    margin: 0 auto;
}
.content h1 {
    font-size: 18px;
    font-weight: 400;
    justify-content: space-between;
    margin: 15px 0px;
    color: var(--primary-color);
}
.radioGroup {
    flex-direction: row !important;
}
.fullWidth {
    width: 85%;
    margin: 13px 0px !important;
}
.halfWidth {
    width: 40%;
}

.flexBoxFirst {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
}
.flexBoxSecond {
    width: 70%;
}
.content p {
    color: var(--primary-color);
}
.referre {
    font-size: 13.5px !important;
    font-family: "Montserrat", sans-serif !important;
    padding-bottom: 4px !important;
}
.radio span:nth-child(2) {
    color: var(--primary-color) !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    font-family: "Montserrat", sans-serif !important;
    padding-bottom: 4px !important;
}
.formLabel {
    color: var(--primary-color) !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    font-family: "Montserrat", sans-serif !important;
    padding-bottom: 4px !important;
}
.error{
    color: red !important;
}
.rowItem {
    margin-bottom: 20px;
    margin-top: 5px;
    display: flex;
    align-items: center;
}
.flexComp{
    display: flex;
    justify-content: space-between;
}
.textField {
    width: 67% !important;
    margin-right: 10px !important;
}
.dateField{
    width: 57% !important;
    margin-right: 10px !important;  
}
.rowItem button {
    margin: 0px 10px !important;
}
.content .saveButtom {
    padding: 8px 90px !important;
    height: 40px !important;
    padding-top: 20px;
    width: auto !important;
    border: 1px solid var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    color: var(--primary-bg-color) !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    border-radius: 2px !important;
    transition: all 0.4s !important;
    margin: 20px 4px !important;
}
.content button:hover {
    cursor: pointer !important;
    color: var(--primary-bg-color) !important;
    background-color: var(--primary-color) !important;
}
.btnCopy {
    min-width: 120px;
}
.refHead {
    color: var(--primary-color);
    padding: 10px;
    margin-top: 20px !important;
    width: 100%;
    background-color: var(--primary-bg-color);
    font-size: 16px !important;
    font-weight: 500;
}
.content div {
    color: var(--primary-color);
}
.tableFooter {
    display: flex;
    height: 50px;
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: flex-end;
}
