.locationContainer {
  background: rgba(45, 65, 65, 1);
  width: 90%;
  margin: 0 auto;
}

.locationHeader {
  margin-top: 18px;
  padding: 28px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.locationHeader h2 {
  font-family: "Lora", sans-serif !important;
  font-weight: bold;
  font-size: 21px;
  color: var(--primary-bg-color);
}
.locationHeader h3,
.locationHeader p {
  font-family: "Lora", sans-serif !important;
  font-style: italic;
  color: var(--primary-bg-color);
}

.locationHeader h3 {
  font-size: 18px;
}
.locationHeader p {
  font-size: 14px;
  margin-top: 12px;
}
.mapContainer {
  position: relative;
  width: 100%;
  height: 450px;
  margin: 20px 0px;
  border-radius: 8px;
  overflow: hidden;
}
.locationDetails {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 5;
  background-color: white;
  padding: 5px;
  padding-left: 10px;
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  cursor: pointer;
}
.locationDetails h3,
.locationDetails p {
  font-family: "Lora", sans-serif !important;
  font-size: 16px;
}
