.container {
    width: calc(100% - var(--nav-width));
    margin: 0 auto;
    height: var(--top-bar-height);
    z-index: 1000;
    position: fixed; 
    background-color: white;
    border-left: 1px solid rgb(236, 236, 236); 
}
.content {
    width: 97%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}
.container h2 {
    font-size: 20px;
    font-weight: 500;
    color: var(--text-color);
    margin: 0px;
}
.profile {
    width: 150px;
    height: 50px;
    margin-right: 15px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    color: var(--text-color);
    background-color: #EAE9E9;
}
.profile:hover {
    cursor: pointer;
}
.profile img {
    height: 80%;
    margin: 5px;
}
.profile p {
    margin-left: 10px;
    color: var(--text-color);
}
.spacer {
    height: var(--top-bar-height);
} 
@media only screen and (max-width: 900px) {
    .content {
        width: calc(100% - var(--nav-width) - 80px);
        margin-left: auto;
        margin-right: 10px;
    }
    .container h2 {
        font-size: 14px;
    }
    .profile {
        height: 40px;
        width: 120px;
    }
    .profile p {
        margin-left: 5px;
    }
}