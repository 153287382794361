.modal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    width: 230px;
    padding: 30px;
    background-color: white;
    border-radius: 4px;
}
.radio{
    display: flex;
}
.radio p{
    margin-top: auto;
    margin-bottom: auto;
    font-family:"Montserrat", sans-serif;
    font-weight: 600;
}
.container h1 {
    font-size: 16px; 
    text-align: center;
    margin: 10px 0px;
}
.textField {
    margin: 10px 0px !important;
    font-size: 14px !important;
    width: 100%;
}