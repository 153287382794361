.container {
    width: 100%;
    height: 100%;
}
.content {
    width: 97.5%;
    margin: 0 auto;
}
.header {
    margin: 15px 0px;
}
.serviceBox {
    width: 300px;
    height: 220px;
    margin: 20px 0px;
    margin-right: 20px;
}
.serviceList {
    display: flex; 
    flex-wrap: wrap;
}
.alignCenter {
    display: flex;
    justify-content: center;
    height: 120px;
}