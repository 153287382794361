.container {
    width: 97%;
    margin: 0 auto;
}
.content {
    padding: 10px;
    height: calc(100vh - 170px);
    overflow-y: scroll;
    display: flex;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97%;
    margin: 10px auto;
}
