.container {
    width: 100%;
    margin: 10px 0px;
}
.content {
    width: 90%;
    margin: 0 auto;
}
.content h1 {
    font-size: 18px;
    font-weight: 400;
    margin: 15px 0px;
    color: var(--primary-color);
}
.content p {
    color: var(--primary-color);
}
.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 25px 0px;
}
.textField ,
.searchField {
    width: 48% !important;
    position: relative !important; 
    color: var(--primary-color) !important;
}
.textField div{
    background-color: var(--primary-bg-color) !important;
    color: var(--primary-color) !important;
}
.notify {
    width: 100%;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
}
.label {
    color: var(--primary-color) !important;
    font-size: 14px !important;
    margin-bottom: 5px !important;
}