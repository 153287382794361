.container {
    width: calc(100% - 3px);
    position: relative;
}
.searchInput {
    width: calc(100% - 3px);
    height: 54px;
    font-weight: 500; 
    border: 1px solid rgb(0, 0, 0,0.2);
    margin-bottom: 5px !important;
    border-radius: 4px !important;
    text-indent: 15px !important;
    font-size: 15px;
    background-color: transparent;
}
.smallTextField {
    height: 38px!important;
}
.searchInput:focus {
    border: 1.5px solid #000;
    opacity: 1;
}
.searchInputError {
    width: 100%;
    height: 50px;
    font-size: 15px; 
    border: 1px solid red !important; 
    margin-bottom: 5px !important;
    border-radius: 4px !important;
    text-indent: 15px !important;
}
.autoComplete {
    width: 500px;   
    display: flex !important;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 70px;
    z-index: 1000;
    background-color: var(--primary-bg-color);
    color: #333 !important;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
} 
.whiteAutoComplete {
    background-color: white !important;
}
.loader {
    padding: 30px 0px;
}
.listItem {
    width: 100%;
}
.error {
    font-size: 13px;
    color: red;
}
#div {
    background-color: var(--primary-bg-color) !important;
}
#white {
    background-color: white !important;
}

@media only screen and (max-width: 600px) {
    .autoComplete {
        width: calc(100vw - 100px);
        left: 10px;
    }
    .searchInput,
    .searchInputError {
        width: 100%;
        flex: 1;
    }
}