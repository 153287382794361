.container {
    position: relative;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
}
/* .container input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */
.meetingContainer {
    min-width: 400px;
}
.header {
    display: flex;
    place-items: center;
    margin-bottom: 15px;
}
.headerTitle {
    flex: 1;
}
.headerTitle:hover {
    cursor: move;
}
.showError {
    position: absolute;
    inset: 0;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 2;
    display: flex;
    justify-content: center;
    place-items: center;
}
.showError p {
    color: red;
}
.errorClose {
    position: absolute !important;
    top: 10px;
    right: 10px;
}
.inputDuration {
    position: relative;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    padding: 3px 18px;
}
.inputDurationLabel {
    position: absolute !important;
    top: -8px !important;
    left: 9px;
    background-color: white;
    padding: 0px 5px !important;
}
.checkboxWeekLabel {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
}
.checkboxWeekLabelActive {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
    background-color: var(--primary-color) !important;
}

.actions {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    gap: 7px;
    padding: 10px;
}
@media only screen and (max-width: 700px) {
    .container {
        max-height: 400px;
    }
    .meetingContainer {
        min-width: auto;
    }
    .actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 7px;
        padding: 10px;
    }
}
