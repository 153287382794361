.container {
    width: 100%; 
    height: calc(100vh - var(--main-navbar-height) - 30px);
    overflow-y: scroll;
}
.content {
    width: 100%;
    display: flex;
}
.sideBar {
    width: 220px;
}
.mainContent {
    width: calc(100% - 200px); 
    height: calc(100vh - var(--main-navbar-height) - 30px);
    overflow-y: scroll;
}