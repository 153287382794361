.modal {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    width: 600px;
    position: relative;
    margin: 0 auto;
}
.close {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
}
.paper {
    padding: 0px 30px;
}
.paper h1 {
    font-size: 20px;
    padding-top: 20px;
    margin: 10px;
    font-weight: 500;
}
.row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0px;
}
.catName {
    width: 96% !important;
    margin: 10px !important;
} 
.switch {
    margin: 10px !important;
}

@media only screen and (max-width: 700px) {
    .paper {
        padding: 0px 15px;
    }
    .row {
        flex-direction: column;
        align-items: flex-start;
    }
    .catName {
        width: 96%;
    }
}