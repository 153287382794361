.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    width: 400px;
    padding: 25px;
    background-color: var(--primary-bg-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.container p {
    color: var(--primary-color);
    font-size: 16px;
    text-align: center;
    width: 100%;
    margin: 15px 0px;
} 
.container button {
    align-self: center;
    margin: 20px 10px;
    padding: 12px 20px;
    background-color: var(--primary-color);
    color: var(--primary-bg-color);
    border-radius: 2px;
    border: 1px solid var(--primary-color);
}
.container button:hover {
    cursor: pointer;
    background-color: var(--primary-bg-color);
    color: var(--primary-color);
}
.btnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnContainer button {
    margin: 10px 20px;
}