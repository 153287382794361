
.popContent {
    padding: 10px;
}
.popContent p {
    padding: 10px;
}
.popContent p:hover {
    cursor: pointer;
    background-color: rgb(0, 0, 0,0.05);
}