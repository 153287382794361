.container {
    width: 100%;
    margin-bottom: 30px;
}
.content {
    width: 96%;
    margin: 0 auto;
    margin-top: 50px;
}
.content h1 {
    font-size: 18px;
    color: var(--primary-color);
    font-weight: 500;
}
.styleCK {
    margin-top: 10px;
}
.styleCK > div:nth-child(2) > div:nth-child(3) > div:first-child {
    padding-left: 30px !important;
    min-height: 200px;
}
.content p {
    font-size: 14px;
    margin-top: 10px;
    color: var(--primary-color);
}
.content hr {
    margin: 25px 0px;
}
.infoContent {
    display: flex;
    justify-content: space-between;
}
.leftContent {
    width: 48%; 
    display: flex;
    flex-direction: column;
}
.rightContent {
    width: 48%;
}
.textField label {
    font-size: 13px !important;
    color: var(--primary-color) !important;
    margin-bottom: 5px !important;
}
.imgUploader {
    width: 100%; 
    display: flex;
    align-items: center;
    border: 1px solid rgb(45, 65, 65,0.5);
    justify-content: center;
    flex-direction: column;
    border-radius: 2px;
    padding: 25px 0px;
    margin: 15px 0px;
}
.imgUploader p {
    margin: 10px 0px;
    font-size: 13px ;
    color: var(--primary-color);
}
.imgUploader:hover {
    cursor: pointer;
}
.imgContainer {
    width: 100%;
    position: relative;
    height: 250px;
    border: 1px solid rgb(45, 65, 65,0.5);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgContainer img {
    max-width: 90%;
    max-height: 90%;
}
.imgContainer video {
    max-width: 90%;
    max-height: 90%;
}
.edit {
    position: absolute !important;
    top: 10px;
    right: 60px;
    z-index: 20;
}
.view {
    position: absolute !important;
    top: 10px;
    right: 110px;
}
.delete {
    position: absolute !important;
    top: 10px;
    right: 10px;
    z-index: 20;
}
.edit ,
.view,
.delete {
    background-color: white !important;
}
.listContainer {
    display: flex;
    flex-direction: column;
}
.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}
.listItem p {
    font-size: 14px;
    color: var(--primary-color);
}
.textField {
    width: 100% !important;
    margin: 15px 0px !important;
}
.imgRow {
    display: flex;
    justify-content: space-between;
    margin: 15px 0px;
}
.imgMainContainer {
    width: 48%;
}
.buttonRow {
    margin-top: 30px;
}
.error {
    font-size: 13px;
    color: red;
}
.POContainer .header { 
    display: flex;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: space-between;
}

.POContainer h2 {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400; 
    color: var(--primary-color);
}
.POContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.POContent button {
    margin-left: 15px;
    color: var(--primary-color);
}
.POContents {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.actions {
    display: flex;
    align-items: flex-start;
}
.maxQuota {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.maxQuota .textField {
    width: 200px;
}

.formGroup {
    width: 100%;
    margin: 40px 0px;
} 
.formGroup span {
    font-size: 12px;
    opacity: 0.6;
}
.formControl svg {
    opacity: 0.5;
}
.file {
    background-color: var(--primary-color);
    color: var(--primary-bg-color);
    display: flex;
    align-items: center;
    height: 50px; 
    width: 135px;
    padding: 0px 10px;
    font-size: 13px;
    border-radius: 4px;
}
.file svg {
    margin-left: 15px;
}
.textField {
    margin: 15px 0px !important;
}

.buttonRow button {
    margin: 0px 10px !important;
}

.row1 {
    width: 100%;
}
.fileUploader {
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fileInfo {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border:  1px solid rgb(0, 0, 0,0.2);
    padding: 0px 10px;
    border-radius: 4px;
    /* margin: 0px 20px; */
    height: 40px;
}
.fileInfo span {
    font-size: 14px;
}
.fileUploader {
    position: relative;
}
.fileUploader svg {
    margin: 0px 10px;
}
.fileUploader svg:hover {
    cursor: pointer;
}
.fileUploader button {
    width: 140px;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: var(--primary-bg-color);
    font-size: 15px;
    background-color: var(--primary-color);
}
.labelDoc {
    display: flex;
    align-items: center;
}
.flexAlignRow {
    display: flex;
    align-items: center;
}
.error {
    color: red;
    font-weight: 400;
    font-size: 13px;
}
.mapHere {
    width: 100%;
    position: absolute;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
}
