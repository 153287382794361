.modal {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    width: 650px;
    position: relative;
    margin: 0 auto;
}
.close {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
}
.paper {
    padding: 0px 30px;
}
.paper h1 {
    font-size: 20px;
    padding-top: 20px;
    margin: 10px;
    font-weight: 500;
}
.row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0px;
    justify-content: center;
}
.catName {
    width: 96% !important;
    margin: 10px !important;
} 
.switch {
    margin: 10px !important;
}
.imgContainer {
    width: 46%;
    margin: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.imgContainerFull {
    width: calc(100% - 40px);
    margin: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.imgContainer label,
.imgContainerFull label {
    font-size: 14px;
    margin: 10px 0px;
    opacity: 0.75;
}
.imgContent {
    width: 100%;
    background-color: rgb(0, 0, 0,0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 150px;
}
.imgContent img {
    max-width: 90%;
    max-height: 90%;
}
.imgContent:hover {
    cursor: pointer;
}
.error {
    font-size: 13px;
    color: red;
    display: block;
    margin: 5px 0px;
    text-align: center;
}
.imgMain {
    display: flex;
    width: 100%;
}
@media only screen and (max-width: 700px) {
    .paper {
        padding: 0px 15px;
    }
    .row {
        flex-direction: column;
        align-items: flex-start;
    }
    .catName {
        width: 96%;
    }
}