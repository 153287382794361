.container {
    width: 100%;
}
.content {
    display: flex;
    flex-direction: column;
}
.head {
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600; 
}
.serviceContent {
    margin: 15px 0px;
}
.serviceContent h1,
.underlineHead,
.head {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Lora', sans-serif  !important;
    margin: 10px 0px;
    color: var(--primary-color);
}
.serviceContent span {
    font-size: 12px;
    font-family: 'Lora', sans-serif  !important;
    color: var(--primary-color);
}
.globalGivingContent {
    display: flex;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 1px;
    border: 1px solid var(--primary-color);
}
.anotherProject {
    width: 120px;
    height: 120px;
    border: 4px solid var(--primary-color);
    border-radius: 4px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2D4141;   
}
.anotherProject:hover {
    cursor: pointer;
}
.anotherProject p {
    text-align: center;
    font-size: 13px;
    color: var(--primary-bg-color);
    margin-top: 10px;
}
.anotherProject svg {
    color: var(--primary-bg-color);
    font-family: 'Lora', sans-serif  !important;
}
.underlineHead {
    padding-bottom: 6px;
    border-bottom: 1px solid var(--primary-color);
    width: 100%;
    display: flex;
    align-items: center;
}
.underlineHead svg {
    margin-left: 15px;
}
.underlineHead svg:hover {
    cursor: pointer;
}
.textField {
    width: 100% !important;
    position: relative;
    margin: 15px 0px;
}
.btnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}
.btnContainer button {
    margin: 0px;
}
.addressText {
    width: 250px;
    margin: 10px 0px;
    color: var(--primary-color);
}