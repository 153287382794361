.container {
    width: 100%;
    margin: 0 auto;
}
.content {
    width: 97%;
    margin: 0 auto;
}
.header {
    width: 100%;
    margin: 25px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header h1 {
    font-size: 22px;
    font-weight: 500;
}
.paper {
    padding: 10px !important;
    display: flex;
    margin: 10px 0px;
}
.eventConf {
    flex: 1;
    margin: 0px 20px;
}
.eventConf h1 {
    font-size: 18px;
    font-weight: 500;
}
.eventInfo p {
    display: flex;
    margin: 5px 0px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.05);
    align-items: center;
    justify-content: space-between;
}
.eventInfo span:nth-child(1) {
    opacity: 0.84;
    margin-right: 20px;
}
.actions {
    margin-left: 40px;
}
.eventConf p {
    margin-left: 0px !important;
}
.eventConf ol {
    margin-left: 15px;
}
.loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.notFound {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 40px;
}
