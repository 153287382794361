.btnPoper {
    position: relative;
    margin-left: 10px;
}
.btnPoper svg:hover {
    cursor: pointer;
}
.btnPoper pre{
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 380px;
    height: 120px; 
    line-height: 18px;
    left: 50%; 
    bottom: 0%;
    transform: translate(-50% ,-30%); 
    padding: 10px; 
    padding-left: 15px;
    border-radius: 2px;
    transition: all .5s;
    opacity: 0;
    /* display: none; */
    font-size: 13px;
    z-index: -1000;
}
.leftPoper pre {
    left: -10px;
    top: 0%;
    transform: translate(-100%,-40%);
}
.rightPoper pre {
    right: -10px;   
    top: 0%;
    transform: translate(5%,-40%);
}
.btnPoper pre::after {
    content: "";
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: white;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%,0px) rotate(45deg);
} 
.leftPoper pre::after {
    left: 100%;
    top: 40%;
    right: -10px;
}
.rightPoper pre::after {
    right: 100%;
    top: 40%;
    left: -0px;
}
 
.svg:hover + pre { 
    opacity: 1;
    /* display: flex; */
    z-index: 1000;
}