.btnPoper {
    position: relative;
}
.btnPoper p{
    position: absolute;
    background-color: white;
    width: 300px;
    top: -90px;
    left: 50%;
    transform: translate(-50% ,-10px); 
    padding: 10px;
    border-radius: 2px;
    transition: all .5s;
    opacity: 0;
}
.btnPoper p::after {
    content: "";
    width: 20px;
    height: 20px;
    background-color: white;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%,0px) rotate(45deg);
} 
.btnPoper:hover  p { 
    opacity: 1;
}