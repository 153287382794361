.root {
    width: 100%;
    min-height: 500px;
}
.header {
    display: flex;
    justify-content: space-between;
    place-items: center;
    background-color: whitesmoke;
    padding: 5px 10px;
    margin-bottom: 5px;
}
.header button {
    background-color: white;
}
.header .today {
    display: flex;
    justify-content: center;
    place-items: center;
    padding: 5px;
    min-width: 150px !important;
    max-width: max-content !important;
}
.header .today p {
    font-weight: 600;
    text-align: center;
}

.headerSelect {
    background-color: white;
    min-width: 150px !important;
}

.header .headerSelect * {
    border: none !important;
}

.header .headerSelect * :focus {
    background-color: white;
}

.day {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0px auto;
    background-color: rgb(63, 132, 235, 0.8);
    display: flex;
    justify-content: center;
    place-items: center;
}

.day p {
    color: white;
    font-size: 10px;
    font-weight: bold;
}

.event {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    overflow: hidden;
    gap: 5px;
}
.event span {
    color: black;
}

@media only screen and (max-width: 700px) {
    .header {
        flex-direction: column;
        padding: 10px;
    }
    .header .headerSelect {
        margin-top: 10px;
        width: 100%;
        max-width: auto;
    }
}
