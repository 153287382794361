.container {
    width: calc(100% - 30px);
    min-width: 900px;
    padding: 15px;
    border-radius: 2px;
    margin-bottom: 10px;
}
.tableContent {
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
}
.tableContent p{
    font-size: 14px;
    width: 14.2%;
    padding: 0px 10px;
    word-break: break-word;
    color: var(--primary-color);
}
.btnContainer {
    width: 14.2%;
    padding: 0px 10px;
}
.btnContainer button {
    width: 100%;
    margin: 5px 0px;
}