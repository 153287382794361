.imgContainer {
    width: 100%;
    height: 100%;
    border: 8px solid var(--primary-bg-color);
    border-radius: 4px;
    box-sizing: border-box;
}
.imgContainer img {
    width: calc(100%);
    height: 100%;
    object-fit: cover;
}
.textContent:hover {
    cursor: pointer;
}
.textContent p { 
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: 'Lora',sans-serif !important;
    color: var(--primary-bg-color);
}
.iconContent {
    width: 60px;
    height: 60px;
    background-color: var(--primary-color);
    border-radius: 100%;
    border: 1px solid var(--primary-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
.iconContent img {
    width: 60%;
    height: auto;
}