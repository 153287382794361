.container {
    width: 100%; 
    height: 100vh; 
} 
.catContainer {
    width: 97%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.contentContainer {
    width: calc(32% - 10px);
    padding: 20px 10px;
    border-radius: 2px;
    background-color: rgba(90, 90, 90, 0.05);
}
.breadCrump {
    display: flex;
    align-items: center;
    height: 40px;
    width: 97%;
    margin: 0 auto;
    margin-bottom: 20px;
}
.breadItem {
    display: flex;
    align-items: center;
    text-transform: capitalize;
}
.breadItem svg{
    color: rgb(116, 116, 116);
}

@media only screen and (max-width: 900px) {
    .container {
        width: 98%;
        margin: 0 auto;
    }
}