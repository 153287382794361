.container {
    width: 100%;
    padding-bottom: 100px;
    padding-top: 20px;
}
.content {
    width: 96%;
    margin: 0 auto;
}
.styleCK > div:nth-child(2) > div:nth-child(3) > div:first-child p {
    margin-left: 0px !important;
}
.styleCK > div:nth-child(2) > div:nth-child(3) > div:first-child ul {
    padding-left: 20px !important;
}
.styleCK > div:nth-child(2) > div:nth-child(3) > div:first-child ol {
    padding-left: 20px !important;
}
.styleCK > div:nth-child(2) > div:nth-child(3) > div:first-child {
    padding-left: 15px !important;
    min-height: 200px;
}
.content h1 {
    color: #5b5959;
    line-height: 28px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin: 10px 0px;
}
.content h1 {
    margin-top: 20px;
    font-size: 22px;
}
.rowFlexStart {
    display: flex;
    align-items: center;
    width: 48%;
}
.item {
    display: flex;
    align-items: center;
}
.content p {
    color: var(--primary-color);
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    line-height: 28px;
    font-weight: 400;
}

.contentContainer {
    padding: 15px 0px;
    width: 100%;
}
.content h2 {
    font-family: "Montserrat", sans-serif;
    color: #2d4141;
    font-weight: 600;
    font-size: 20px;
    margin: 0px;
}
.duration label {
    color: #2d4141;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
}
.row {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}
.halfField,
.fullWidth {
    width: 48% !important;
    margin: 10px 0px !important;
}
.headersFlex {
    display: flex;
}
.rowThreeItem {
    display: flex;
    justify-content: space-between;
    gap: 25px;
}
.halfField label,
.fullWidth label,
.dayCol label,
.catContent label {
    font-size: 14px !important;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 5px !important;
    color: var(--primary-color) !important;
}
.dFlexComp {
    width: 48%;
    display: flex;
    align-items: center;
}
.itemCenter {
    display: flex;
    height: min-content;
    place-items: center;
    margin-top: 20px !important;
}
.col {
    width: 48%;
    display: flex;
    flex-direction: column;
}
.fullWidth {
    width: 100% !important;
}
.textarea {
    height: 120px;
}
.imgContainer {
    width: 100%;
    height: 85%;
    margin-top: 3px;
    border: 1px solid rgba(51, 51, 51, 0.3);
    border-radius: 4px;

    position: relative;
}
.centerAbs:hover {
    cursor: pointer;
}

.centerbbs:hover {
    cursor: pointer;
}
.centerbbs {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
}
.imgMainContainer {
    width: 48%;
    margin-top: 4px;
}
.imgMainContainer label {
    color: var(--primary-color);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 14px;
}

.centerbbs span {
    font-size: 13px;
    color: #2d4141;
    opacity: 0.7;
    text-align: center;
}
.centerbbs svg {
    font-size: 60px;
    color: #2d4141;
    opacity: 0.7;
    margin-bottom: 15px;
}
.centerAbs {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
}
.centerAbs span {
    font-size: 13px;
    color: #2d4141;
    opacity: 0.7;
    text-align: center;
}
.centerAbs svg {
    font-size: 60px;
    color: #2d4141;
    opacity: 0.7;
    margin-bottom: 15px;
}
.imgContent {
    display: flex;
}
.imgComp {
    width: 16%;
    margin: 2%;
    position: relative;
}
.imgComp img {
    width: 100%;
    border-radius: 1px;
    height: 100%;
    object-fit: cover;
}
.imgComp span {
    background-color: red;
    border-radius: 100%;
    position: absolute;
    padding: 1px 6px;
    color: white;
    display: inline-block;
    top: 4px;
    right: 4px;
}
.imgComp span1 {
    background-color: white;
    border-radius: 100%;
    position: absolute;
    padding: 1px 2px;
    color: grey;
    display: inline-block;
    top: 4px;
    right: 28px;
}

.Downloadicon {
    font-size: 16px;
    padding-top: 1px;
}
.imgComp span:hover {
    cursor: pointer;
}

.imgComp span1:hover {
    cursor: pointer;
}
.catContent {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.labelFlex {
    display: flex;
}
.catContent label {
    font-size: 14px;
    color: var(--primary-color);
    margin-bottom: 5px;
    font-weight: 500;
}
.error {
    font-size: 13px;
    color: #f44336 !important;
    margin: 3px 14px;
}
.catBox {
    min-height: 52px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid rgba(51, 51, 51, 0.3);
}
.tag {
    display: flex;
    font-size: 12px;
    padding: 1px 8px;
    padding-right: 0px;
    margin: 5px 8px;
    border-radius: 12px;
    align-items: center;
    background-color: var(--primary-color);
    color: white;
}
.tag svg {
    font-size: 25px;
    margin-left: 5px;
}
.tag svg:hover {
    cursor: pointer;
}
.dayCol {
    display: flex;
    width: 48%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.dayCol input,
.selectInput {
    width: 100%;
    height: 52px;
    font-size: 14px;
    outline: none;
    text-indent: 10px !important;
    border: 1px solid rgba(51, 51, 51, 0.3);
    background-color: white;
    color: var(--primary-color);
    border-radius: 4px;
}
.dayCol .row {
    justify-content: space-between;
    width: 100%;
}
.formGroup {
    display: flex;
    flex-direction: column;
    width: 30%;
}
.btnContainer {
    display: flex;
    margin: 25px 0px;
    justify-content: center;
    align-items: center;
}
.btnContainer button {
    margin: 0px 15px;
}

.duration {
    width: 48%;
    display: flex;
    flex-direction: column;
}
.inputs {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
}
.smallInput {
    width: 30% !important;
}

.cancelbutton {
    display: inline-block;
    width: 190px;
    height: 37px;
    cursor: pointer;
    background-color: #9d3831;
    color: white;
    border-radius: 8px;
}
.savebutton {
    display: inline-block;
    width: 190px;
    height: 37px;
    cursor: pointer;
    background-color: #6d6d6d;
    color: white;
    border-radius: 8px;
}
.publishbutton {
    display: inline-block;
    width: 190px;
    height: 37px;
    cursor: pointer;
    background-color: #314041;
    color: white;
    border-radius: 8px;
}
.para {
    margin-left: 0;
    padding-bottom: 6px;
}
.previewbutton {
    display: inline-block;
    width: 190px;
    height: 37px;
    cursor: pointer;
    background-color: #c4c4c4;
    color: white;
    border-radius: 8px;
}

@media only screen and (max-width: 600px) {
    .rowFlexStart,
    .imgMainContainer,
    .col,
    .halfField,
    .rowFlexStart,
    .fullWidth,
    .dFlexComp,
    .duration {
        width: 100% !important;
    }
    .row {
        flex-direction: column;
    }
    .imgContainer {
        min-height: 200px;
    }
    .btnContainer button {
        margin: 0px 2px;
    }
}
