.container {
    width: 120px;
    height: 120px;
    border: 4px solid var(--primary-color);
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 10px;
}
.container:hover {
    cursor: pointer;
}
.container img {
    width: 100%; 
    height: 100%;
    object-fit: cover;
    position: absolute;
}
.container p {
    font-size: 13px;
    text-align: center;
    z-index: 10;
    font-family: "Lora", sans-serif !important;
    color: var(--primary-bg-color);
}
.icon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    color: var(--primary-bg-color);
}