.Modal {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
.container {
    width: 500px; 
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    position: relative;
}
.container .close {
    position: absolute;
    right: 15px;
    top: 15px;
    color: black;
}
.container svg:hover {
    cursor: pointer;
}
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px;
}
.content h1 {
    font-size: 20px;
    color: var(--text-color);
    font-weight: 500;
    margin-bottom: 15px;
}

.profileImg {
    width: 60px;
    height: 60px;
    padding: 10px;
    border-radius: 200%;
}
.textField {
    margin: 12px 0px !important;
    font-size: 13px !important;
}
.formContent {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.updatePassword {
    margin: 10px 0px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    padding: 10px 14px;
    padding-right: 20px;
    font-size: 14px;
    width: 200px;
    justify-content: space-between;
    font-weight: 400;
    border: none;
    letter-spacing: 0.2px;
    border-radius: 10px;
    outline: none;
    overflow: hidden;
    color: white;
    background-color: var(--primary-color);
    position: relative;
    transition: .3s all;
}
.updatePassword:hover {
    cursor: pointer;
    transform: translateY(-1.5px);
}
.updatePassword:active {
    cursor: pointer;
    transform: translateY(0.5px);
}
@media only screen and (max-width: 600px) { 
    .container { 
        width: 80vw;
        padding: 20px;
    }
}