.container {
    display: flex; 
    min-height: 400px;
    justify-content: space-between;
}
.infoContent {
    width: calc(60% - 40px);
    padding: 20px;
    min-height: calc(400px - 40px);
    margin-right: 2%;
    background-color: var(--primary-bg-color);
    border-radius: 8px;
}
.actionContent { 
    width: calc(38% - 40px);
    padding: 20px;
    min-height: calc(400px - 40px);
    background-color: var(--primary-bg-color);
    border-radius: 8px;
} 
.tabs span {
    text-transform: capitalize !important;
    font-size: 14px;
}
.content {
    margin-top: 10px;
}
.content p {
    font-size: 14px;
    margin: 10px 0px;
    color: var(--primary-color);
}
.content span {
    font-size: 13px;
    color: var(--primary-color);
}
.topHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.content h1 {
    font-size: 16px;
    font-weight: 600;
    margin: 15px 0px;
    color: var(--primary-color);
    text-transform: capitalize;
}
.topHeader h1 {
    margin: 10px 0px;
}
.infoSection {
    display: flex;
    margin: 5px 0px;
}
.infoSection img {
    width: 140px;
    height: 140px;
    object-fit: cover;
}
.infoSectionContent {
    margin-left: 15px;
}
.infoSectionContent p {
    display: flex;
    margin-right: 10px 0px;
    align-items: center;
    font-family: 'Lora', sans-serif  !important;
}
.infoSectionContent p svg {
    margin-right: 10px;
}
.catList {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0px;
}
.catList span {
    background-color: var(--primary-color);
    padding: 6px 8px;
    border-radius: 8px;
    color: var(--primary-bg-color);
    margin-right: 10px;
    font-size: 13px;
    text-transform: uppercase;
} 
.actionContent h1 {
    font-size: 16px;
    font-weight: 600;
    margin: 15px 0px;
    color: var(--primary-color);
    text-transform: capitalize;
}
.qtyContent {
    display: flex;
    padding: 6px 0px;
    border-bottom: 0.5px solid rgba(45, 65, 65, 0.5);
}
.qtyContent h2,
.qtyContent h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 15px 0px;
    color: var(--primary-color);
    text-transform: capitalize;
}
.qtyContent h2 {
    flex: 1;
}
.qtyContent h3 {
    width: 70px;
    text-align: right;
}
.qtyController {
    display: flex;
    align-items: center;
}
.qtyController:hover {
    cursor: pointer;
}
.qtyController input {
    width: 30px;
    height: 28px;
    text-indent: 5px;
    border: none;
    color: var(--primary-bg-color);
    background-color: var(--primary-color);
}
.qtyController svg {
    border: 1px solid var(--primary-color);
}
.btnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
}
.btnContainer button {
    width: 220px;
    font-size: 14px;
}
.dateMapper {
    display: flex;
    justify-content: space-between;
}  
.timeSlots { 
    display: flex;
    width: 48%;
    height: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
}
.timeSlot {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    margin: 5px;
    border-radius: 2px;
}
.timeSlot span {
    font-size: 14px;
    color: var(--primary-color);
}
.timeSlot:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--primary-bg-color);
}
.timeSlot:hover span {
    color: var(--primary-bg-color);
}
.activeTimeSlot {
    background-color: var(--primary-color);
    color: var(--primary-bg-color);
}
.activeTimeSlot span {
    color: var(--primary-bg-color);
}
.datePicker {
    border: 2px solid var(--primary-color);
}
.datePicker div:first-child {
    background-color: var(--primary-bg-color) !important;
    color: var(--primary-color) !important;
}
@media only screen and (max-width: 1300px) {
    .infoContent {
        width: calc(55% - 40px);       
    }
    .actionContent {
        width: calc(54% - 40px);
    }
}

@media only screen and (max-width: 1050px) {
    .dateMapper {
        flex-direction: column;
    }
    .datePicker {
        width: auto;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 900px) {
    .container {
        flex-direction: column;
    }   
    .infoContent {
        width: calc(100% - 40px);
        margin-bottom: 20px;
    }
    .actionContent {
        width: calc(100% - 40px);
    }
    .dateMapper {
        flex-direction: row;
    }
}

@media only screen and (max-width: 500px) {
    .dateMapper {
        flex-direction: column;
    }
    .infoSection {
        flex-direction: column;
    }
    .infoSection img {
        width: 100px;
        height: 100px;
    }
    .infoSectionContent {
        margin-left: 0px;
    }
    .content h1 {
        font-size: 14px;
    }
    .actionContent h1 {
        font-size: 14px;
    }
}