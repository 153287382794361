.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content {
    width: 600px;
    height: auto;
    padding: 20px;
    background-color: white;
    position: relative;
}
.closeIcon {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
}
.content h1 {
    font-size: 18px;
    font-weight: 400;
    margin: 15px 0px;
}
.textField {
    width: 100% !important;
    margin: 20px 0px !important;
}