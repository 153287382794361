.container {
    width: 100%;
    padding-bottom: 100px;
    padding-top: 20px;
}
.content {
    width: 96%;
    margin: 0 auto;
}
.row {
    display: flex;
    justify-content: space-between;
}
.styleCK {
    margin-top: 10px;
}
/* .styleCK > div:nth-child(2) > div:nth-child(3) > div:first-child {
    padding-left: 20px !important;
    min-height: 200px;
} */
.styleCK > div:nth-child(2) > div:nth-child(3) > div:first-child p {
    margin-left: 0px !important;
}
.styleCK > div:nth-child(2) > div:nth-child(3) > div:first-child ul {
    padding-left: 20px !important;
}
.styleCK > div:nth-child(2) > div:nth-child(3) > div:first-child ol {
    padding-left: 20px !important;
}
.errorMessage {
    color: #f44336 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}
.imgContainerdown {
    width: 48%;

    margin-top: 10px;
    border: 1px solid rgba(51, 51, 51, 0.3);
    border-radius: 4px;

    height: 170px;
    position: relative;
}
.leftContent {
    width: 48%;
    display: flex;
    flex-direction: column;
}
.textField {
    width: 100% !important;
    margin: 15px 0px !important;
}
.textField label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
}
.content h1,
.content h2 {
    color: var(--primary-color);
    font-weight: 700;
    margin: 10px 0px;
}
.content h1 {
    margin-top: 20px;
    font-size: 22px;
}
.rowFlexStart {
    display: flex;
    align-items: center;
    width: 48%;
}
.item {
    display: flex;
    align-items: center;
}
.content p {
    color: var(--primary-color);
}
.content h2 {
    font-size: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(72, 101, 101, 0.2);
}
.contentContainer {
    padding: 15px 0px;
}
.row {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}
.halfField,
.fullWidth {
    width: 48% !important;
    margin: 10px 0px !important;
}
.halfField label,
.fullWidth label,
.dayCol label,
.catContent label {
    font-size: 14px !important;
    font-weight: 500;
    margin-bottom: 5px !important;
    color: var(--primary-color) !important;
}
.dFlexComp {
    width: 48%;
    display: flex;
    align-items: center;
}
.col {
    width: 48%;
    display: flex;
    flex-direction: column;
}
.fullWidth {
    width: 100% !important;
}

.centerAbs:hover {
    cursor: pointer;
}
.centerAbs {
    position: absolute;
    top: 50%;
    left: 40%;
    display: flex;
    flex-direction: row;
    transform: translate(-50%, -50%);
}
.centerAbs span {
    font-size: 13px;
    color: #2d4141;
    opacity: 0.7;
    margin-top: 20px;
    margin-left: 30px;
}
.centerAbs svg {
    font-size: 60px;
    margin-top: 25px;
    color: #2d4141;
    opacity: 0.5;
    margin-bottom: 15px;
}

.imgContainer {
    width: 48%;
    border: 1px solid rgba(51, 51, 51, 0.3);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}
.centerbbs:hover {
    cursor: pointer;
}
.centerbbs {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
}
.centerbbs span {
    font-size: 13px;
    color: #2d4141;
    opacity: 0.7;
    text-align: center;
}
.centerbbs svg {
    font-size: 60px;
    color: #2d4141;
    opacity: 0.7;
    margin-bottom: 15px;
}
.imgContent {
    display: flex;
}
.imgComp {
    width: 16%;
    margin: 2%;
    position: relative;
}
.imgComp img {
    width: 100%;
    border-radius: 1px;
    height: 100%;
    object-fit: cover;
}
.imgComp span {
    background-color: red;
    border-radius: 100%;
    position: absolute;
    padding: 1px 6px;
    color: white;
    display: inline-block;
    top: 4px;
    right: 4px;
}
.imgComp span:hover {
    cursor: pointer;
}
.catContent {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.catContent label {
    font-size: 14px;
    color: var(--primary-color);
    margin-bottom: 5px;
    font-weight: 500;
}
.error {
    font-size: 13px;
    color: #f44336;
    margin: 3px 14px;
}
.catBox {
    min-height: 52px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid rgba(51, 51, 51, 0.3);
}
.tag {
    display: flex;
    font-size: 12px;
    padding: 1px 8px;
    padding-right: 0px;
    margin: 5px 8px;
    border-radius: 12px;
    align-items: center;
    background-color: var(--primary-color);
    color: white;
}
.tag svg {
    font-size: 25px;
    margin-left: 5px;
}
.tag svg:hover {
    cursor: pointer;
}
.dayCol {
    display: flex;
    width: 48%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.dayCol input,
.selectInput {
    width: 100%;
    height: 52px;
    font-size: 14px;
    outline: none;
    text-indent: 10px !important;
    border: 1px solid rgba(51, 51, 51, 0.3);
    background-color: white;
    color: var(--primary-color);
    border-radius: 4px;
}
.dayCol .row {
    justify-content: space-between;
    width: 100%;
}
.formGroup {
    display: flex;
    flex-direction: column;
    width: 30%;
}
.btnContainer {
    display: flex;
    margin: 25px 0px;
    justify-content: center;
}
.btnContainer button {
    margin: 0px 15px;
}

.duration {
    width: 48%;
    display: flex;
    flex-direction: column;
}
.duration label {
    color: #2d4141;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
}
.inputs {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
}
.smallInput {
    width: 30% !important;
}

.smallInput label {
    max-width: 80%;
}
