

.row1 {
    width: 100%;
    margin: 10px 0px;
}
.fileUploader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fileInfo {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border:  1px solid rgb(0, 0, 0,0.2);
    padding: 0px 10px;
    border-radius: 4px;
    margin: 0px 20px;
    height: 40px;
}
.row1 label {
    font-size: 14px;
    opacity: 0.6;
    margin-bottom: 5px;
}

.fileInfo span {
    font-size: 14px;
}
.fileUploader {
    position: relative;
}
.fileUploader svg { 
    fill: #000;
    color: #000;
}
.fileUploader svg:hover {
    cursor: pointer;
}
.fileUploader button {
    width: 140px;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: var(--primary-bg-color);
    font-size: 15px;
    background-color: var(--primary-color);
    cursor: pointer;
}
.error {
    font-size: 12.5px;
    margin-top: 5px;
    color: red;
    margin-left: 15px;
}