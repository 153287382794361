.container {
    width: 100%;
    padding-bottom: 30px;
} 
.head {
    font-size: 32px;
    color: var(--primary-color);
    text-align: center;
    font-family: 'Lora',sans-serif !important;
    font-weight: bold;
} 
.content {
    margin: 25px 0px;
    padding: 25px 0px;
    height: auto; 
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header {
    height: 60px;
    margin: 15px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header img {
    height: 60%;
    width: auto;
}
.header h1 {
    font-size: 22px;
    font-family: 'Lora',sans-serif !important;
    margin-left: 15px;
    font-weight: bold;
    color: var(--primary-bg-color);
}
.checkBox {
    width: 35px;
    margin-top: 10px;
    position: relative;
    height: 35px;
}
.checkBox img {
    width: 35px;
    height: 35px;
}
.checkBox span {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: var(--secondary-color);
    position: absolute;
    top: 48.5%;
    left: 47%;
    transform: translate(-48%, -46%);
    display: inline-block;
}
.checkBox:hover {
    cursor: pointer;
}
.contentInfo {
    width: 90%;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
}
.leftContent {
    width: 50%;
    display: flex;
    justify-content: space-between;
}
.col {
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: center;
}
.col svg {
    color: var(--primary-bg-color);
    align-self: flex-end;
}
.col svg:hover {
    color: var(--primary-bg-color);
    align-self: flex-end;
}
.col:nth-child(2) {
    width: 65%;
    align-items: flex-start;
}
.icon {
    height: 60px;
    width: auto;
}
.col p {
    font-size: 14px;
    color: var(--primary-bg-color);
    margin-top: 10px;
}
.info {
    display: flex;
    align-items: center;
} 
.info p {
    margin-left: 15px;
}
.rightContent button {
    border-color: var(--primary-bg-color);
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: var(--primary-bg-color);
    border-radius: 2px;
    height: 40px;
}
.rightContent svg {
    margin-right: 10px;
} 
.journeyMap {
    width: 90%;
    margin: 40px 0px;
    max-width: 1000px;
}
.line {
    margin: 25px 0px;
    width: 100%;
    border-bottom: 1px solid rgba(230, 225, 210, 0.3);
}
.btnEnd {
    display: flex;
    margin: 25px 0px;
    justify-content: flex-end;
}