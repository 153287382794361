.container {
    width: 97%;
    margin: 0 auto;
}
.header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    height: 60px;
}
.leftHeader {
    display: flex;
    align-items: center;
}
.leftHeader p {
    font-size: 14px;
    margin-right: 10px;
}
.rightHeader button {
    margin-left: 10px !important;
}
.warehouseBlock {
    width: 30%;
    margin: 20px;
}
.select {
    width: 100%;
}
.rightHeader {
    display: flex;
    height: 100%;
    align-items: center;
}
.search {
    width: 200px !important;
    margin-bottom: 10px !important;
    margin-left: 15px !important;
}
.selectAll {
    display: flex;
    align-items: center;
}
.selectAll p {
    margin-right: 10px;
}
.btnCenter {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.switch {
    display: flex;
    margin: 0px 10px;
    align-items: center;
}
.grey,
.green,
.orange {
    padding: 2px 4px;
    font-size: 11px;
    border-radius: 2px;
    letter-spacing: 0.4px;
    font-weight: 500;
    color: white;
    background-color: grey;
    text-transform: uppercase;
}
.green {
    background-color: green;
}
.orange {
    background-color: orange;
}
@media only screen and (max-width: 600px) {
    .header {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }
    .search {
        margin-left: 0px !important;
    }
    .rightHeader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}