.container {
    width: 97%;
    margin: 0 auto;
}
.header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 60px;
}
.leftHeader {
    display: flex;
    align-items: center;
}
.leftHeader p {
    font-size: 14px;
    margin-right: 10px;
}
.rightHeader button {
    margin-left: 10px !important;
}
.warehouseBlock {
    width: 30%;
    margin: 20px;
}
.select {
    width: 100%;
}
.rightHeader {
    display: flex;
    height: 100%;
    align-items: center;
}
.search {
    width: 200px !important;
    margin-bottom: 10px !important;
    margin-left: 15px !important;
}
.newContent {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
}
.newContent p {
    font-size: 13px;
}
.new {
    background-color: red;
    border-radius: 20px;
    color: white;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: bold;
    margin-left: 15px;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    min-width: 250px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}
