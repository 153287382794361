.flexHeaderContainer {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
    color: var(--primary-bg-color);
}

.info {
    border-top: 0.5px solid rgba(230, 225, 210, 0.26);
    border-bottom: 0.5px solid rgba(230, 225, 210, 0.26);
    margin-top: 24px;
    padding: 26px 0;
    width: 48%;
}
.flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
    flex-wrap: wrap;
}
.flexContainerfooter {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 28px;
    flex-wrap: wrap;
}
.onlineLabel {
    display: inline;
    height: 75%;
    font-size: "10px";
    background-color: #45993e;
    border-radius: 10%;
}
.tags {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.tag {
    padding: 6px 16px;
    border: 1px solid #a5978b;
    background: rgba(42, 49, 46, 0.8);
    border-radius: 4px;
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;
}

.button {
    background: transparent;
    border: 0;
    padding: 8px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--primary-bg-color);
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
}

.button svg {
    margin-right: 6px;
}

.infoText {
    color: var(--primary-bg-color);
    font-size: 14px;
    font-style: italic;
    font-family: "Lora", sans-serif !important;
    line-height: 1.5;
}

.borderBottom {
    border-bottom: 1px solid rgba(230, 225, 210, 0.26);
    padding-bottom: 26px;
}

.priceContainer {
    margin-bottom: 0;
    justify-content: flex-start;
}
.discountedPrice {
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: line-through;
    color: #a5978b;
    font-size: 18px;
    margin-right: 24px;
}

.price {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--primary-bg-color);
    font-size: 18px;
}

.actionButtons {
    display: flex;
}

.buyVoucherButton {
    background: var(--primary-color);
    border: 0;
    padding: 12px 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--secondary-color);
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 4px;
}

.bookNowButton {
    background: var(--primary-color);
    border: 0;
    padding: 12px 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--primary-bg-color);
    font-size: 13px;
    margin-left: 24px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 4px;
}
.counterButton {
    color: var(--primary-bg-color);
    font-size: 14px;
    cursor: pointer;
    padding: 4px 10px;
}

.covidText {
    color: var(--primary-bg-color);
    font-size: 12px;
}

.galleryContainer {
    width: 48%;
    height: max-content;
}

.gallery {
    height: 100%;
    width: 100%;
}
.mainImage {
    width: 100%;
    height: max-content;
    position: relative;
}
.mainImg {
    border-radius: 4px;
    max-height: 450px;
    object-fit: cover;
}
.gallery img {
    width: 100%;
    height: 100%;
}

.imagesContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    height: 25%;
}

.subImage {
    position: relative;
    width: 32%;
    margin-left: 1%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
    margin-bottom: 10px;
}
.subImage img {
    width: 100%;
}

.navigatorText {
    color: var(--secondary-color);
    margin-bottom: 14px;
    font-family: "Lora", sans-serif !important;
    font-weight: bold;
    font-style: italic;
}
.navigatorText a,
.navigatorText span {
    color: var(--primary-bg-color);
    text-decoration: none;
    margin-bottom: 14px;
    font-family: "Lora", sans-serif !important;
    font-weight: bold;
    font-style: italic;
}

.navigatorText span {
    margin: 0 14px;
}
.pointer:hover {
    cursor: pointer;
}

@media only screen and (max-width: 2560px) {
    /* 4k screen */
    .mainImage {
        max-width: 1105px;
        max-height: 1105px;
    }
}
@media only screen and (max-width: 1440px) {
    /* large laptop */
    .mainImage {
        max-width: 620px;
        max-height: 620px;
    }
}
@media only screen and (max-width: 1024px) {
    /* laptop */
    .mainImage {
        max-width: 470px;
        max-height: 470px;
    }
}

@media only screen and (max-width: 990px) {
    .flexHeaderContainer {
        flex-direction: column;
        align-items: center;
    }
    .info {
        width: 100%;
    }
    .galleryContainer {
        width: 100%;
    }
    /* .mainImage {
        height: 340px;
    } */
}
@media only screen and (max-width: 650px) {
    .shareButton {
        padding: 4px 2px !important;
    }
}
@media only screen and (max-width: 470px) {
    .imagesContainer {
        justify-content: center;
    }
}
