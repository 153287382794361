.container {
    width: 100%; 
    height: 100vh; 
} 

@media only screen and (max-width: 900px) {
    .container {
        width: 98%;
        margin: 0 auto;
    }
}