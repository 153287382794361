.Modal {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
.container {
    width: 500px;
    height: auto;
    display: flex;
    background-color: #fff;
    border-radius: 4px;
    flex-direction: column;
    padding: 20px;
}
.container h1 {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin: 15px 0px;
}
.container button {
    margin: 15px 0px !important;
}
@media only screen and (max-width: 600px) { 
    .container { 
        width: 80vw;
        padding: 20px;
    }
}