.container {
    width: 100%;
}
.content {
    width: 96%;
    margin: 0 auto;
}

.tabs{ 
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

p {
    margin-left: 15px;
}

h3:hover{
    border-bottom: 1px solid rgb(72, 101, 101,0.3);
    margin-bottom: 5px;
}

.tabs button span {
    font-size: 14px;
    color: var(--primary-color);
    font-weight: 600;
}
.flexEnd {
    flex: 1;
    width: 100%; 
    display: flex;
    justify-content: flex-end;
}