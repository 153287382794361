/* .container {
    width: calc(100% - 30px);
    padding: 15px;
    border-radius: 2px;
    margin-bottom: 10px;
}
.tableContent {
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
}
.tableContent p{
    font-size: 14px;
    width: 14.2%;
    color: #333;
}
.btnContainer {
    width: 14.2%;
}
.btnContainer button {
    width: 100%;
    margin: 5px 0px;
} */
.container {
    width: calc(100% - 30px);
    min-width: 900px;
    padding: 15px;
    border-radius: 2px;
    margin-bottom: 10px;
}
.headContent {
    display: flex;
    width: 100%;
    padding: 15px 0px; 
}
.accordian {
    width: 98%;
    border-radius: 8px !important;
    border: none !important;
    margin:  20px auto !important;
    box-shadow: none !important;
}
.accordian * {
    outline: none !important;
}
.item {
    flex: 1;
    padding: 0px 10px;
    width: 20%;
}
.item span {
    opacity: 0.6;
    font-size: 15px;
}
.item p {
    font-size: 15px;
    margin-top: 15px;
    color: var(--primary-color);
    font-weight: 500;
}

.action {
    height: 100%;
    display: flex;
    align-items: center;
}
.action button {
    height: 36px;
    padding: 0px 12px;
    border-radius: 2px;
    font-size: 12.5px; 
}
.accordianData {
    width: 95%;
    margin-left: 5%;
}
.accordianData .headContent {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.accordianData .item p,
.accordianData .item span {
    font-size: 13.5px !important;
    word-break: break-all;
    padding: 0px 20px;
}
.accordianData .item {
    width: 12%;
}
.btnContainer {
    flex-direction: column;
    display: flex;
}
.btnContainer button {
    margin: 4px 0px;
}
