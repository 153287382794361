.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    width: 400px;
    padding: 25px;
    background-color: var(--primary-bg-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.container p {
    color: var(--primary-color);
    font-size: 16px;
    text-align: center;
    margin: 15px 0px;
}
.container span {
    font-family: 'Lora', sans-serif  !important;
    font-size: 13px;
    margin: 12px 0px;
    display: block;
    color: var(--primary-color);
}
.container button {
    align-self: center;
    margin: 20px 0px;
    padding: 12px 20px;
    background-color: var(--primary-color);
    color: var(--primary-bg-color);
    border: none;
    border-radius: 2px;
}
.container button:hover {
    cursor: pointer;
}