.container {
    width: 100%;
    height: calc(100vh - var(--main-navbar-height) - 30px);
    overflow-y: scroll;
}
.content {
    width: 96%;
    margin: 0 auto;
}
.tabContent { 
    display: flex;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
}
.tabContent h4 {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-color);
}
.selectContent,
.journeyType {
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 2px;
    min-width: 100px;
    background-color: var(--primary-color);
    color: var(--primary-bg-color);
    padding: 5px;
    position: relative;
}
.selectContent::after {
    height: 100%;
    width: 5px;
    content: "";
    position: absolute;
    right: 0px;
    background-color: var(--primary-color);
}
.selectContent:hover,
.journeyType:hover {
    cursor: pointer;
}
.journeyType {
    margin: 10px 0px;
}
.journeyContents {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0px;
}
.createAJourney ,
.journeyBox{
    width: 30%;
    margin-right: 3%;
    margin-bottom: 3%;
    max-width: 300px;
    background-color: var(--primary-color);
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.createAJourney svg {
    width: 100px !important;
    height: 100px !important;
    fill: var(--primary-bg-color) !important;
}
.createAJourney p {
    font-size: 14px;
    margin-top: 15px;
    color: var(--primary-bg-color);
}
.createAJourney:hover {
    cursor: pointer;
} 
.imgContent {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.imgContent img{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
}
.imgContent img:nth-child(2) {
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
.infoCircle,
.progressContent {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: var(--primary-color);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.infoCircle p {
    font-size: 12px;
    color: var(--primary-bg-color);
    text-align: center;
}
.progressContent::after {
    content: "";
    width: 100%;
    height: 100%; 
    border: 13px solid rgba(44, 61, 61, 0.5);
    border-radius: 100%;
    box-sizing: border-box;
    position: absolute;
}
.progressBar {
    width: 100% !important;
    z-index: 1;
    height: 100% !important;
    position: absolute !important;
    top: 0% !important;
    transform: rotate(-90deg) !important;
    color: var(--primary-bg-color) !important;
}
.progressContent span {
    font-size: 14px;
    color: var(--primary-bg-color);
}
.headContent {
    width: calc(100% - 20px);
    height: calc(20% - 20px);
    padding: 10px;
} 
.headContent p {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-bg-color);
}
.status {
    position: absolute;
    z-index: 4;
    top: 10px;
    left: 10px;
    background-color: var(--primary-color);
    color: var(--primary-bg-color);
    font-size: 12px;
    padding: 4px 15px;
    border-radius: 2px;
}
.header {
    width: 100%;
    height: 50px;
    display: flex;
    margin: 20px 0px;
    align-items: center;
}
.header h4 {
    font-size: 15px;
    margin-right: 20px;
    font-weight: 600;
    color: var(--primary-color);
    padding-bottom: 6px;
    box-sizing: border-box;
}
.header h4:hover {
    cursor: pointer;
}
.activeHeader {
    border-bottom: 2px solid var(--primary-color);
}
.journeyBox:hover {
    cursor: pointer;
}