.container,
.primaryContainer {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background-color: var(--primary-bg-color);
    padding: 10px;
    border-radius: 4px;
    background-size: cover;
    border: 1px solid rgba(51, 51, 51, 0.1);
}
.container:hover,
.primaryContainer {
    cursor: pointer;
}
.primaryContainer {
    background-color: var(--primary-color);
} 
.infoContent {
    height: 100%;
    padding: 10px 0px; 
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
} 
.headContent h1 {
    font-size: 15px;
    color: var(--primary-bg-color);
    font-family: 'Lora', sans-serif  !important;
    font-weight: 700;
    text-transform: capitalize;
}
.headContent span {
    font-size: 13px;
    color: var(--primary-bg-color);
    font-family: 'Lora', sans-serif  !important;
}
.infoContent h4 {
    width: 70px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: 'Lora', sans-serif  !important;
    color: var(--primary-bg-color);
}
.primaryContainer h4,
.primaryContainer span,
.primaryContainer h1 {
    color: var(--primary-bg-color);
}