.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.container {
    max-width: 80vw;
    min-width: 40vw;
    max-height: 80vh;
    padding: 25px;
    background-color: var(--primary-bg-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
}
.container p {
    color: var(--primary-color);
    font-size: 14px;
    /* text-align: justify; */
    margin: 15px 0px;
    text-align: center;
    width: 100%;
}

.closeButton {
    text-align: right;
    color: var(--primary-color);
    width: 100%;
    cursor: pointer;
}

.container .image {
    width: 100%;
    margin: 10px 0;
    text-align: center;
}

.container .content {
    padding: 10px;
}

.container hr {
    width: 100%;
    background-color: var(--primary-color);
    height: 1px;
    opacity: 0.5;
}

.container .goal {
    width: 100%;
    text-align: center;
}

.content p {
    font-size: 14px;
    line-height: 24px;
}

.title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: var(--primary-color);
    margin: 15px 0;
}

.infoHeader {
    width: 100%;
    text-align: center;
}

.infoHeader h5 {
    text-transform: uppercase;
    color: var(--primary-color);
    font-weight: 600;
    margin-right: 5px;
}

.progressContent {
    min-width: 300px;
    background-color: rgba(45, 65, 65, 0.3);
    height: 5px;
    border-radius: 4px;
    display: inline-block;
}
.progressBar {
    height: 100%;
    border-radius: 4px;
    background-color: var(--primary-color);
}

@media only screen and (max-width: 450px) {
    .container {
        max-width: 80vw;
    }
}
