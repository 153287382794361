.container {
    width: 100%;
    border-right: 1px solid rgba(45, 65, 65, 0.1);
}
.content {
    width: 95%;
    margin: 0 auto;
}
.listItem,
.activeListItem {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin: 5px 0px; 
    color: var(--primary-color);
    opacity: 0.7;
}
.activeListItem {
    opacity: 1;
}
.listItem:hover {
    cursor: pointer;
    opacity: 1;
}
.listItem p,
.activeListItem p {
    margin-left: 15px;
    font-size: 14px;
    color: var(--primary-color);
} 