.container {
    width: 100%; 
    padding-bottom: 50px;
}
.header {
    height: 80px;
    width: 97.5%;
    margin: 0 auto;
    background-color: var(--primary-bg-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.header:hover {
    cursor: pointer;
}
.header h1 {
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
    color: var(--primary-color);
} 
.content {
    width: 97.5%;
    margin: 0 auto;
}
.head {
    font-size: 14px;
    font-weight: 600;
    padding: 20px 0px;
    color: var(--primary-bg-color);
    text-transform: uppercase;
}
.head:hover {
    cursor: pointer;
}

@media only screen and (max-width: 1110px) {
    .content {
        width: 96%;
    }
}

@media only screen and (max-width: 550px) {
    .header {
        height: 60px;
    }
    .head {
        padding: 10px 0px;
    }
}