.contentContainer { 
    width: 100%;
}
.contentContainer h2{ 
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(72, 101, 101,0.2);
    color: var(--primary-color);
    font-weight: 700;
    font-size: 16px;
    margin: 10px 0px;
}
.cards {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
}
.card {
    width: 23%;
}
.card img {
    height: 16px;
}
.card h4 {
    font-size: 15px;
    color: var(--primary-color);
    font-weight: 600;
    border-bottom: 1px solid rgb(72, 101, 101,0.2);
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.item {
    display: flex;
    align-items: center;
}
.errorMessage{
    color:#f44336;
    font-size: 12px;
    font-weight: 500;
}
.item img {
    width: 20px;
    margin-right: 10px;
}
.item p {
    flex: 1;
    color: var(--primary-color);
}
.item span {
    padding: 3px;
}
.error {
    font-size: 13px;
    margin-bottom: 10px;
    color: red;
}