.container {
    height: 60px;
    border-top: 1px solid rgba(0,0,0,0.1);
    width: 100%;
    box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
}
.content {
    width: 96%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card {
    width: 24%;
    height: 100%;
    display: flex;
    border-right: 1px solid rgba(0,0,0,0.1);
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
}
.card:hover {
    cursor: pointer;
}
.card h1 {
    font-size: 15px;
    text-align: center;
}
.activeCard {
    border-bottom: 2px solid #333;
}