.container {
    width: 100%; 
    overflow-y: scroll;
    background-color: var(--primary-bg-color);
    position: relative;
}
.content {
    width: 96%;
    margin: 0 auto;
    padding-bottom: 50px;
}
.btnEditTop {
    top: 5%;
    right: 5%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btnEditTop svg {
    height: 20px;
    margin-right: 10px;
}
.btnContainer {
    margin: 25px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnContainer button {
    margin: 0px 10px;
    font-weight: 600px;
    width: 140px;
}
.modalContent {
    width: 100%;
}
.modalContent h4{
    margin: 10px 0px;
    color: var(--primary-color);
    font-weight: 600;
    text-align: center;
}
.modalContent p {
    text-align: left !important;
}
.modalContent p b{
    font-weight: 600;
    font-size: 14px;
}
.option {
    display: flex;
    align-items: center;
}
.option p,
.modalContent p {
    font-size: 14px;
    margin: 5px 0px;
}
.alignCenter {
    text-align: center !important;
    width: 100%;
    margin: 25px 0px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
}
.btnCenter {
    display: flex;
    justify-content: center;
}
.btnCenter button {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-bg-color);
    color: var(--primary-color);
}
.head {
    color: var(--primary-color);
    font-family: 'Lora', sans-serif  !important;
    font-size: 24px;
    font-weight: 600;
}
.p {
    color: var(--primary-color);
    margin-top: 10px;
    line-height: 20px;
}
.p span {
    text-decoration: underline;
    text-transform: capitalize;
}
.journeyMap {
    padding: 40px;
    background-color: var(--primary-color);
}