.container {
    width: 97%;
    margin: 0 auto;
}

.flex {
    width: 100%;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flexData {
    width: 48%;
}

.chart {
    width: 98%;
    margin: 0 auto;
}
th {
    width: 80%;
}
.topHeader {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}
.topHeader p {
    margin-left: 0;
}
.flexData p {
    width: 100%;
    margin-left: 30%;
    margin-top: 15px;
}
.leftHeader {
    width: 70%;
}
.rightHeader {
    width: 30%;
}
.rightHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
