.root {
  /* display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: start; */
  width: 85%;
  margin: 24px auto;
}

.Topic {
  font-size: 14px;
  font-weight: 600;
  color: #e6e1d2;
  font-family: "Lora", sans-serif !important;
}

.Content {
  padding: 20px;
  color: #e6e1d1;
  text-align: left;
  line-height: 24px;
  font-size: 16px;
  word-break:normal;
  font-family: "Lora", sans-serif !important;
}

.card {
  background: rgba(45, 65, 65, 0.6);
  backdrop-filter: blur(12px);
  border-radius: 6px;
  -webkit-backdrop-filter: blur(12px);
  color: var(--primary-bg-color);
  font-size: 14px;
  width: 30%;
  margin-left: 3%;
}
.cardHeader {
  padding: 28px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(229, 225, 212, 0.6);
  font-size: 14px;
}
.cardBody {
  padding: 0 20px;
  margin: 20px 0;
  text-align: left;
  line-height: 24px;
  font-size: 14px;
  font-family: "Lora", sans-serif !important;
}

@media only screen and (max-width: 1100px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 726px) {
  .cards {
    flex-wrap: wrap;
  }
  .card {
    width: 100%;
    margin-bottom: 10px;
  }
}
