.container {
    width: 100%;
}
.content {
    width: 96%;
    margin: 0 auto;
}

.tabs {
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

p {
    margin-left: 15px;
}
h2 {
    margin-left: 15px;
}
.formContainer {
    width: 60%;
    justify-content: baseline;
    display: flex;
    gap: 15px;
}
.formContainer > button {
    margin-top: 15px;
}
/* h3{
    margin-left: 15px !important;
    margin-bottom: 5px;
} */
h3:hover {
    border-bottom: 0px solid;
    margin-bottom: 5px !important;
}

.tabs button span {
    font-size: 14px;
    color: var(--primary-color);
    font-weight: 600;
}
.flexEnd {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.TextField {
    background-color: rgb(243, 243, 243);
}
.button {
    color: white !important;
    background-color: var(--primary-color) !important;
    width: 100px !important;
    align-self: center;
}
