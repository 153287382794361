.container {
    margin: 15px 0px;
    width: 100%;  
}
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.content {
    width: 600px;
    min-height: 250px;
    padding: 25px;
    background-color: var(--primary-bg-color);
    border-radius: 2px;
    position: relative;
}
.payNow {
    width: 100% !important;
}
.close {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    z-index: 1000 !important;
}
.displayContainer {
    width: calc(100% - 50px);
    position: absolute;
    height: calc(100% - 50px);
    background-color: rgba(255, 255, 255,0.98);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.displayContainer p {
    margin-top: 20px;
} 