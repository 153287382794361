.container {
  background-image: 
    url("../../../assets/img/wood_bg.jpeg");
  background-size: cover;
  padding: 24px 0;
}
.content {
  width: 90%;
  margin: 0 auto;
}

.tags {
  padding: 12px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tagGroup {
  width: 30%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.iconContainer {
  color: var(--secondary-color);
  background: rgba(230, 225, 210, 0.2);
  border-radius: 50%;
  padding: 8px;
  margin-right: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  min-width: 32px;
  justify-content: center;
}
.iconContainer svg,
.iconContainer img {
  max-width: 80%;
  max-height: 80%;
}

.tag {
  width: 70%;
  padding: 8px 12px;
  border: 1px solid #a5978b;
  background: rgba(230, 225, 210, 0.1);
  border-radius: 4px;
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--primary-bg-color);
  min-width: 180px;
  text-align: center;
}

.buttonGroup {
  display: flex;
  margin-top: 4px;
  justify-content: center;
}
.amenitiesButton {
  background: var(--primary-bg-color);
  border: 0;
  margin: 8px 0 0 8px;
  padding: 12px 14px;
  display: flex;
  align-self: end;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .tags {
    flex-direction: column;
  }
  .tagGroup {
    width: 100%;
  }
}
