.header {
    width: 100%;
    height: 50px;
    display: flex;
    margin: 20px 0px;
    align-items: center;
}
.header h4 {
    font-size: 15px;
    margin-right: 20px;
    font-weight: 600;
    color: var(--primary-color);
    padding-bottom: 6px;
    box-sizing: border-box;
}
.header h4:hover {
    cursor: pointer;
}
.activeHeader {
    border-bottom: 2px solid var(--primary-color);
}