.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content {
    width: 500px;
    min-height: 200px;
    background-color: white;
    border-radius: 4px;
    position: relative;
    padding: 40px;
}
.close {
    top: 10px !important;
    position: absolute !important;
    right: 10px !important;
}
.content h1{
    font-size: 22px;
    text-align: center;
    margin-bottom: 15px;
}
.content p {
    word-break: break-all;
    margin-left: 0px;
    padding: 20px 0px;
    text-align: center;
    line-height: 24px;
}