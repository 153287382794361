.container {
    width: 100%; 
}
.content {
    width: calc(100% - 40px);
    padding: 15px 20px;
    border-radius: 8px;
    background-color: var(--primary-bg-color);
}
.content h1 {
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600;
}

.qtyContent {
    display: flex;
    padding: 6px 0px;
    border-bottom: 0.5px solid rgba(45, 65, 65, 0.2);
}
.qtyContent:hover {
    cursor: pointer;
}
.qtyContent h2,
.qtyContent h3 {
    font-size: 14px; 
    margin: 15px 0px;
    color: var(--primary-color);
    text-transform: capitalize;
}
.qtyContent h2 {
    flex: 1;
}
.qtyContent h3 {
    width: 70px;
    text-align: right;
}
.qtyController {
    display: flex;
    align-items: center;
}
.qtyController:hover {
    cursor: pointer;
}
.qtyController input {
    width: 30px;
    height: 28px;
    text-indent: 5px;
    border: none;
    color: var(--primary-bg-color);
    background-color: var(--primary-color);
}
.qtyController svg {
    border: 1px solid var(--primary-color);
}
.listItem {
    padding: 8px 0px;
    display: flex;
    justify-content: space-between;
}
.listItem p b {
    font-weight: 700;
}
.discountCode {
    display: flex; 
    margin: 10px 0px; 
    flex-wrap: wrap;
}
.discountCode p {
    font-size: 14px;
    color: var(--primary-color);
    width: 100%;
    margin-bottom: 5px;
}
.textField {
    width: 70% !important;
}
.textField input {
    font-size: 14px !important;
    padding: 12px !important;
}
.discountCode button {
    height: 40px;
    margin-left: 10px;
}
.code {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.code span {
    margin: 4px 0px;
    font-size: 13px;
}
.code span:nth-child(1) {
    background-color: var(--secondary-color);
    color: #538C8C;
    padding: 4px;
}
.btnContainer {
    width: 100%;
    margin: 15px 0px;
    display: flex;
    justify-content: center;
}
.btnContainer button {
    width: 220px;
    font-weight: 600;
}
.span {
    font-size: 13px;
    color: var(--primary-bg-color);
    line-height: 22px;
    font-family: 'Lora', sans-serif  !important;
}
.errorContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.errorContent h1 {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: var(--primary-color);
    margin: 10px 0px;
}
.errorContent span {
    font-size: 13px;
    color: var(--primary-color);
    text-align: center;
}
.errorContent img {
    margin: 20px 0px;
    height: 40px;
}