.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btnCal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    text-transform: capitalize;
}
.btnCal svg {
    margin-right: 5px;
}

.cards {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 15px;
    padding: 10px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.card {
    width: 45%;
}
.item {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    border-bottom: 1px solid rgb(72, 101, 101,0.3);
    margin-bottom: 10px;
}
.card h3 {
    font-size: 14px;
    color: var(--primary-color);
    font-weight: 600;
}
.date {
    width: 100% !important;
    color: var(--primary-color) !important;
    font-size: 14px !important;
    margin: 10px 0px !important;
}
.formGroup {
    display: flex;
    align-items: center;
}
.formGroup label { 
    width: 100px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 600;
    margin: 15px 0px;
}
.dateItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}
.dateItem span {
    font-size: 600;
    font-size: 14px;
    color: var(--primary-color);
}
.dateItem svg {
    color: var(--primary-color);
}
.dateItem svg:hover {
    cursor: pointer;
}
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 15px 0px;
}
.pagination svg {
    color: var(--primary-color);
    margin: 0px 5px;
}
.pagination span {
    color: var(--primary-color);
    font-size: 14px;
    border: 1px solid var(--primary-color);
    margin: 0 5px;
    padding: 3px 7.5px;
    display: inline-block;
    border-radius: 100%;
}
.pagination span:hover,
.pagination svg:hover {
    cursor: pointer;
}
.activeSpan {
    background-color: var(--primary-color);
    color: white !important;
}
.error {
    font-size: 13px;
    color: red;
}
.dateField {
    border: 1px solid var(--primary-color) !important;
    border-radius: 1px !important;
}
.joinedContent {
    display: flex;
    align-items: center;
}
.joinedContent p,
.formGroup p {
    width: 100px;
    margin: 15px 0px !important;
}
.datePicker {
    width: 80% !important;
    margin: 0px 10px !important;
}
.timePicker {
    width: 400px !important;
}
.timePicker p,
.datePicker p {
    width: auto !important;
}
.menuItems div:nth-child(3){
    background-color: #F3F4F4 !important;
    color: var(--primary-color) !important;
    font-size: 14px !important;
}
.cardComp {
    border-bottom: 1px solid rgba(45, 65, 65, 0.1);
    padding: 20px 0px;
    padding-top: 10px;
}
.alignEnd {
    display: flex;
    justify-content: flex-end;
}
.alignEnd svg:hover {
    cursor: pointer;
}

.addScheduleButton{
    text-align: center;
}

.addScheduleButton:hover button{
    background-color: var(--primary-color);
}

.addScheduleButton button{
    padding:10px;
    background-color: var(--primary-color);
}
.addScheduleButton button span{
    color: white !important;
    font-weight: 500;
}