.container {
    padding: 10px; 
    width: calc(100% - 20px);
    margin: 10px 0px;
    display: flex;
    border: 0.5px solid var(--primary-color);
    border-radius: 1px;
}
.imgContent {
    height: 100px;
    width: 100px;
}
.imgContent img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}
.infoContent {
    margin-left: 10px;
    flex: 1;
}
.infoContent h2 {
    font-size: 15px;
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 10px;
}
.infoContent p {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 10px;
    color: var(--primary-color);
}
.infoContent h4 {
    font-size: 15.5px;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--primary-color);
}
.actionContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} 
.qtyController {
    display: flex;
    align-items: center;
}
.qtyController:hover {
    cursor: pointer;
}
.qtyController input {
    width: 30px;
    height: 28px;
    text-indent: 5px;
    border: none;
    color: var(--primary-bg-color);
    background-color: var(--primary-color);
}
.border {
    box-sizing: border-box;
    width: 30px;
    height: 28px;
    border: 1px solid var(--primary-color);
}
.topContent {
    display: flex;
    justify-content: flex-end;
}
.topContent:hover {
    cursor: pointer;
}