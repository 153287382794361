.container {
    width: 100%;
}
.content {
    width: 96%;
    margin: 0 auto;
}

.tabs{ 
    margin: 50px 200px 0px 0px;
    align-items: center;
}
.h3{
    margin-bottom: 20px;
}
.h3:hover{
    margin-bottom: 20px !important;
}
.columnimage {
    padding: 10px;
    margin: 20px;
    float: left;
    width: 200px;
  }
  .columndata {
    float: left;
    width: 70%;
  }
  .row {
      margin-top: 50px !important;
  }
  .row2 {
    display: flex;
    flex-direction: column;
    margin-top: 50px !important;
  }
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .imgContainer{
    padding: 50;
    width:100%;
    height:100%
  }
  .img {
      width: 200px;
      height: 200px;
      border-radius: 200px;  
  }
  .centerAbs {
    background-color: grey;
    border-radius: 200px;
    height: 200px;
  }
  .centerAbs :hover{
    cursor: pointer;
  }
  .textField {
    width: 60% !important; 
    margin-right: 5% !important;
  }   
  .textFieldCategory {
      width: 35% !important; 
  }
  .textField label {
      font-size: 14px !important;
      margin-bottom: 5px !important;
  }
  .textFieldCategory label {
      font-size: 14px !important;
      margin-bottom: 5px !important;
  }
  .textFieldBio {
    margin-top: 30px !important;
    width: 100% !important; 
  }
  .textFieldSetting{
    margin-top: 30px !important;
    width: 40% !important;
    margin-right: 5% !important; 

 }
 .textFieldSetting label{
    font-size: 14px !important;
    margin-bottom: 5px !important;
 }
 .button{
     color: white !important;
     background-color: var(--primary-color) !important;
     width:100px !important;
     align-self: center;
 }
 .button :hover{
    cursor: pointer;

}
.TextField {
    background-color: rgb(243, 243, 243);
}