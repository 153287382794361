
.modal {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}
.container {
    background-color: white;
    border-radius: 4px;
    padding: 10px;
    width: 500px;
}
.categoryController {
    padding: 20px;
    display: flex;
}
.selectContent {
    flex: 1;
    padding: 15px;
    border-right: 1px solid rgb(45, 65, 65,0.15);
}
.selectContent h1 {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-color);
    margin: 10px 0px;
    border-bottom: 1px solid rgb(45, 65, 65,0.15);
}
.rowContent,
.activeRowContent {
    padding: 10px;
    padding-left: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(45, 65, 65,0.15);
}
.rowContent p,
.activeRowContent p {
    font-size: 14px;
    color: var(--primary-color);
}
.rowContent:hover {
    cursor: pointer;
}
.rowContent:hover p{
    font-weight: 600;
    color: var(--primary-color);
}
.activeRowContent p {
    font-weight: 600;
    color: var(--primary-color);
}
.error {
    color: red;
    font-size: 14px;
}
.dEnd {
    margin: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}