.container {
	width: 100%;
}
.content {
	width: 100%;
}
.head,
.bookSubData {
	display: flex;
	width: calc(100% - 20px);
	padding: 2px 10px;
	justify-content: space-between;
} 
.bookSubDataDiv div{
	width: 100%;
}
.bookSubData {
	width: calc(96% - 20px) !important;
	padding: 15px 0px;
	margin-left: auto;
	border-top: 1px solid rgb(0, 0, 0,0.1);
}
.item {
	width: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.item span {
	display: flex;
	flex-direction: column; 
	align-items: flex-start;
	font-size: 13.5px;
	opacity: 0.8;
}
.item p {
	margin-left: 0px;
	margin-top: 15px;
	font-size: 14px;
	font-weight: 600;
}
.item button {
	width: 80% !important;
	padding: 8px 10px !important;
	font-size: 13.5px;
	margin-bottom: 10px;
	border-radius: 4px;
}
.accordion {
	margin-bottom: 20px !important;
	box-shadow: 1px 4px 20px rgb(0, 0, 0,0.1) !important;
	border-radius: 8px;
	border: none !important;
}
.accordion::before {
	display: none !important;
}