.container {
    width: 97%;
    margin: 0 auto;
}
.content {
    padding: 20px;
}
.compContent {
    width: 100%;
    padding: 10px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.compContent h3 {
    font-size: 14px;
    margin-right: 15px;
    font-weight: 500;
}
.textFieldContent {
    margin: 15px 0px;
    display: flex;
    align-items: center;
}
.textField {
    width: 48%;
}
.textFieldContent button{
    margin-left: 15px !important;  
}
.selectors {
    display: flex; 
    margin: 20px 0px;
    flex: 1;
} 
.selectorsTemp {
    display: flex; 
    margin: 20px 0px;
} 
.selectorContainer {
    flex: 2;
}
.radio {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
}
.selectField {
    width: 48% !important;
}
.formContent {
    width: 48%;
}