.container { 
  position: absolute;
  width: 100vw;
  top: 0;overflow-x: hidden;
  left: 0;
  background-color: var(--primary-color);
}
.header {
  height: 80px;
  background-color: var(--primary-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.header h1 {
  font-size: 16px;
  font-weight: 700;
  margin-right: 10px;
  color: var(--primary-color);
}
.content {
  width: 90%;
  margin: 0 auto;
}
.bgContainer {
  background-image: url("../../assets/img/services_bg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}
.serviceList {
  width: 90%;
  margin: 0 auto;
}
.head {
  font-size: 14px;
  font-weight: 600;
  padding: 20px 0px;
  color: var(--primary-bg-color);
  text-transform: uppercase;
}
.head:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1110px) {
  .content {
    width: 96%;
  }
}

@media only screen and (max-width: 550px) {
  .header {
    height: 60px;
  }
  .head {
    padding: 10px 0px;
  }
}
