.container {
    width: 100%; 
    padding: 10px 0px;
}
.row {
    display: flex; 
    align-items: center;
    justify-content: space-between;
}
.fullWidth {
    flex: 2 !important;
    margin: 10px 0px !important;
    margin-right: 10px !important;
}
.halfWidth {
    flex: 1 !important;
    margin: 10px 0px !important;
}
.fullWidth label,
.halfWidth label {
    font-size: 14px;
    color: var(--primary-color);
    margin-bottom: 5px;
}