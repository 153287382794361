.modal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    width: 500px;
    padding: 30px;
    background-color: white;
    border-radius: 4px;
}
.container h1 {
    font-size: 16px;
    color: #333;
    text-align: center;
    font-weight: 600;
    margin: 10px 0px;
}
.textField {
    margin: 10px 0px !important;
    font-size: 14px !important;
}