.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  background-image: linear-gradient(rgba(45, 65, 65, 0.2)),
    url("../../../../assets/img/wood_bg.jpeg");

  background-size: cover;
  padding: 24px 0;
}
.content {
  /* width: 67%; */
  max-height: 90vh;
  overflow-y: scroll;
  background: rgba(83, 92, 89, 0.53);
  border-radius: 4px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}
.checkBoxDiv {
  display: flex;
  align-items: center;
}
.checkBoxDiv span {
  font-size: 14px;
  font-family: "Lora", sans-serif !important;
  font-style: italic;
  letter-spacing: 0.2px;
}
.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #a5978b;
  margin-bottom: 24px;
  padding: 12px 22px;
}
.contentImage {
  max-width: 800px;
  min-height: 80vh;
  display: flex;
  margin: 20px auto;
}

.rightSide {
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  padding: 20px 0px;
}
.rightSide h6 {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-family: "Lora", sans-serif !important;
  font-style: italic;
}
.rightSide p {
  font-family: "Lora", sans-serif !important;
  font-style: italic;
  font-size: 14px;
  padding-top: 5px;
}
.tags {
  padding: 12px 90px;
  column-gap: 80px;
  row-gap: 24px;
  justify-items: center;
  align-items: center;
}

.tagGroup {
  display: flex;
  align-items: center;
}

.iconContainer {
  color: var(--secondary-color);
  background: rgba(230, 225, 210, 0.2);
  border-radius: 50%;
  padding: 10px;
  margin-right: 28px;
}
.iconContainer svg,
.iconContainer img {
  width: 32px;
  height: 27px;
}

.tag {
  padding: 6px 16px;
  border: 1px solid #a5978b;
  background: rgba(230, 225, 210, 0.1);
  border-radius: 4px;
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--primary-bg-color);
  width: 240px;
  text-align: center;
  height: max-content;
}

.buttonGroup {
  display: flex;
  margin-top: 4px;
  justify-content: center;
}
.amenitiesButton {
  background: var(--primary-bg-color);
  border: 0;
  margin: 8px 0 0 8px;
  padding: 12px 14px;
  display: flex;
  align-self: end;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 4px;
}

@media only screen and (max-width: 1240px) {
  .tags {
    padding: 0;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 60px;
  }
}

@media only screen and (max-width: 1100px) {
  .tags {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 726px) {
  .tags {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .content {
    width: 96vw;
  }
  .main {
    padding: 0px 10px;
  }
  .contentImage {
    flex-direction: column;
    width: 100%;
  }
  .leftSide {
    width: 100%;
    max-height: 80vh;
  }
  .leftSide img {
    border-radius: unset;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .rightSide {
    width: calc(100% - 20px);
    height: 200px;
    border-radius: unset;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .info {
    flex-direction: column;
  }
  .info .input {
    width: 100% !important;
  }
  .iconContainer {
    padding: 10px;
    margin-right: 15px;
  }
  .tag {
    padding: 6px 16px;
    width: 190px;
    font-size: 13px;
    margin-left: 0px;
  }
}
