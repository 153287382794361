.container {
    width: 97%;
    margin: 0 auto;
}
.header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 60px;
}
.leftHeader {
    display: flex;
    align-items: center;
}
.leftHeader p {
    font-size: 14px;
    margin-right: 10px;
}
.rightHeader button {
    margin-left: 10px !important;
}
.warehouseBlock {
    width: 30%;
    margin: 20px;
}
.select {
    width: 100%;
}
.rightHeader {
    display: flex;
    height: 100%; 
}
.search {
    width: 200px !important;
    margin-bottom: 10px !important;
    margin-left: 15px !important;
    font-size: 13px !important;
}
.search li {
    font-size: 13px !important;
}
.newContent {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
}
.newContent p {
    font-size: 13px;
}
.new { 
    background-color: red;
    border-radius: 20px;
    color: white;
    padding: 2px 6px;
    height: 14px;
    font-size: 10px;
    font-weight: bold;
}
.old { 
    background-color: rgb(131, 131, 131);
    border-radius: 20px;
    color: white;
    padding: 2px 6px;
    height: 14px;
    font-size: 10px;
    font-weight: bold;
}