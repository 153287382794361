.container {
  background: var(--primary-color);
}
.content {
  width: 90%;
  margin: 0 auto;
}

.highlights {
  background-image: linear-gradient(rgba(45, 65, 65, 0.2)),
    url("../../../assets/img/wood_bg.jpeg");
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 0;
}

.headerTitle {
  display: flex;
  align-items: center;
  color: var(--primary-bg-color);
}

.headerTitle h1 {
  font-family: "Lora", sans-serif !important;
  font-weight: bold;
  font-size: 21px;
  margin-right: 16px;
  color: var(--primary-bg-color);
}

.button {
  color: var(--primary-bg-color);
  border: 0;
  background: transparent;
  font-weight: 600;
  cursor: pointer;
  padding: 8px;
}

.tags {
  padding: 21px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 44px;
  row-gap: 24px;
  max-width: 52%;
  width: 100%;
  margin: 0 auto;
}

.tag {
  padding: 6px 16px;
  border: 1px solid #a5978b;
  background: rgba(42, 49, 46, 0.8);
  border-radius: 4px;
  color: var(--primary-bg-color);
  text-align: center;
  max-width: 430px;
  width: 100%;
  display: flex;
  align-items: center;
}

.barContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
}
.barContainer span {
  margin-left: 8px;
}

.tagTitle {
  padding: 12px;
  min-width: 200px;
}

@media only screen and (max-width: 460px) {
  .tags {
    margin: 0;
  }


.header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
}

@media only screen and (max-width: 1000px) {
  .tags {
    grid-template-columns: 1fr;
  }
}
