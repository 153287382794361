.container {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.content {
    width: 900px;
    position: relative;
    background-color: white;
    border-radius: 2px;
    padding: 30px;
}
.close {
    position: absolute !important;
    right: 10px !important;
    top: 10px !important;
}
.contentContainer {
    width: calc(100% - 0px);
    display: flex;
}
.leftContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.leftContent img {
    max-width: 100%;
}
.zoomSlider {
    display: flex;
    justify-content: space-between;
    place-items: center;
    width: 100%;
    margin-top: 20px;
}
.rightContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.rightContent img {
    max-width: 80%;
    border-radius: 2px;
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
}
.rightContent button {
    margin-top: 30px;
}
.row {
    padding: 30px 0px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.textField {
    width: 50%;
}
.row button {
    margin-left: 20px;
}