.container {
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
    position: relative;
}
.container img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 8px;
}
.prevBtn,
.nextBtn {
    position: absolute !important;
    top: 50% !important;
    transform: translate(0px, -50%) !important;
    z-index: 100 !important;
    color: var(--primary-bg-color) !important;
}
.nextBtn {
    right: 10px !important;
}
.rateContent {
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;
}
@media only screen and (max-width: 700px) {
    .container,
    .container img {
        height: 350px;
    }
}

@media only screen and (max-width: 500px) {
    .container,
    .container img {
        height: 280px;
    }
}

@media only screen and (max-width: 400px) {
    .container,
    .container img {
        height: 220px;
    }
}