.modal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    width: 500px;
    padding: 30px;
    background-color: white;
    border-radius: 4px;
    position: relative;
}
.close {
    position: absolute;
    top: 10px;
    right: 10px;
}

.close:hover {
    cursor: pointer;
}
.container h1 {
    font-size: 16px;
    color: var(--primary-color);
    text-align: center;
    font-weight: 600;
    margin: 10px 0px;
}
.textField {
    margin: 10px 0px !important;
    font-size: 14px !important;
}
@media only screen and (max-width: 550px) {
    .container {
        width: 80vw;
    }
}