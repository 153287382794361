.contentContainer { 
    width: 100%;
}
.contentContainer h2{ 
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(72, 101, 101,0.2);
    color: var(--primary-color);
    font-weight: 700;
    font-size: 16px;
    margin: 10px 0px;
}
.contentContainer h3 {
    font-size: 15px;
    font-weight: 700;
    color: var(--primary-color);
    margin: 10px 0px;
    margin-top: 25px;
}
.labelContainer {
    display: flex;
    flex-direction: column;
}
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.halfField {
    width: 48% !important;
    position: relative;
}
.fullField {
    width: 100% !important;
    position: relative;
}
.halfField label {
    font-size: 13px !important;
    margin-bottom: 5px !important;
    color: var(--primary-color) !important;
}
.rowFlexStart {
    display: flex;
    align-items: center;
    padding: 3px;
    width: 100%;
}
.item {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.formGroup {
    width: 150px;
    margin-right: 15px;
}
.formGroup label {
    font-size: 13px;
    color: var(--primary-color);
}
.formGroup input {
    width: 100%;
    border-radius: 4px;
    height: 54px;
    font-size: 14px;
    margin-top: 6px;
    text-indent: 10px;
    border: 1px solid rgba(51, 51, 51, 0.3);
}
.greyBorder {
    border:2px solid #E1E1E1 !important;
}
.greenBorder {
    border:2px solid #D6E4C4 !important; 
}
.blueBorder {
    border:2px solid #C2E0F5 !important;
}
.error {
    color: #f44336;
    font-size: 0.75em;
    font-weight: 400;
}
.spacer {
    width: 100px;
}
.menuItems div:nth-child(3){
    background-color: #F3F4F4 !important;
    color: var(--primary-color) !important;
    font-size: 14px !important;
}
.item span {
    padding: 3px;
}


.radioitem{
    display: flex;
    align-items: center;
    padding: 0 6px;

  }

  .radioitem label {
    color: #666;
    padding-left: 10px;
    padding-right: 8px;
    font-weight: normal;
  }

  .radioitem input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 2.7px;
    background-clip: content-box;
    border: 2px solid #5B5959;
    background-color: white;
    border-radius: 50%;
  }


.radioitem input[type="radio"]:checked{
    background-color: #5B5959;
  }