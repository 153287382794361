.container {
    width: 100%;
    padding-bottom: 30px;
} 
.container h1 {
    font-size: 32px;
    color: var(--primary-color);
    text-align: center;
    font-family: 'Lora',sans-serif !important;
    font-weight: bold;
}
.container p {
    font-size: 18px;
    color: var(--primary-bg-color);
    margin: 10px 0px;
    text-align: center;
    font-family: 'Lora',sans-serif !important;
}
.content {
    margin: 25px 0px;
    width: (100% - 50px);
    padding: 25px;
    background-color: var(--primary-color);
    height: auto; 
    display: flex;
    flex-direction: column;
    align-items: center;
}
.content h6 {
    font-size: 15px; 
    color: var(--primary-bg-color);
    font-family: 'Lora',sans-serif !important;
    margin: 15px 0px;
} 
.catItems {
    width: 100%;
    margin: 25px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card {
    padding: 15px;
    height: 150px;
    width: calc(23% - 30px);
    position: relative;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.card:hover {
    cursor: pointer;
}
.card img {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card h2 {
    z-index: 1;
    font-size: 16px;
    font-weight: bold;
    font-family: "Lora", sans-serif !important;
    color: var(--primary-bg-color);
}
.card p {
    z-index: 1;
    font-size: 14px;
    font-family: 'Lora',sans-serif !important;
    color: var(--primary-bg-color);
    text-align: left;
    margin-bottom: 0px;
}